import { FC } from "react";
import { useField } from "formik";
import { FormHelperText, InputLabel, Rating, Stack } from "@mui/material";

interface Props {
  name: string;
  label: string;
}
export const CustomRating: FC<Props> = ({ name, label }) => {
  const [field, meta, helpers] = useField({ name });
  const { touched, error } = meta;
  return (
    <Stack spacing={0.5}>
      <InputLabel>{label}</InputLabel>
      <Rating
        precision={0.5}
        max={5}
        name="simple-controlled"
        value={field.value}
        size="medium"
        onChange={(_, newValue) => helpers.setValue(newValue)}
      />
      <FormHelperText error={touched.valueOf() && Boolean(error)}>
        {touched.valueOf() && error}
      </FormHelperText>
    </Stack>
  );
};
