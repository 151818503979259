import React from "react";
import { DataCellProps } from ".";

const Text: React.FC<DataCellProps> = ({ value, isEditing, setValue }) => {
  return (
    <>
      {isEditing ? (
        <textarea
          style={{ zIndex: 20, outline: "none" }}
          value={value}
          onChange={e => setValue(e.target.value)}
          className="w-full h-full p-1 absolute resize-none rounded"
        />
      ) : (
        <div className="w-full h-full flex justify-start overflow-hidden p-1 text-ellipsis">
          {value}
        </div>
      )}
    </>
  );
};

export default Text;
