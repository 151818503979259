import React from "react";
import { DataCellProps } from ".";
import { FiLink } from "react-icons/fi";
import {
  closeDropdown,
  openDropdown,
} from "../../../../../Components/Dropdown/index2";
import { useClickOutside } from "../../../../../Hooks/utils/useClickOutside";

const Url: React.FC<DataCellProps> = ({ value }) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const getContent = () => {
    return (
      <ul className="font-sans text-sm">
        <li className="p-1 hover:bg-indigo-700 hover:text-white rounded cursor-pointer">
          <a href={value}>Download</a>
        </li>
        <li
          className="p-1 hover:bg-indigo-700 hover:text-white rounded cursor-pointer"
          onClick={() => navigator.clipboard.writeText(value)}>
          Copy To Clipboard
        </li>
      </ul>
    );
  };
  useClickOutside(containerRef, closeDropdown);
  return (
    <div
      ref={containerRef}
      className="w-full h-full flex items-center justify-center text-lg text-indigo-700"
      onClick={() => {
        openDropdown(containerRef.current, getContent);
      }}>
      <FiLink />
    </div>
  );
};

export default Url;
