import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { QueryClientProvider, QueryClient, QueryCache } from "react-query";
import { toast } from "react-hot-toast";

const client = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: any) => {
      if (error.code === 401) {
        localStorage.clear();
        toast.error("Session expired. Please login again.");
        window.location.href = "/signin";
      } else {
        toast.error(error.message);
      }
    },
    onSuccess: (data, query: any) => {
      toast.success(query?.meta, {
        id: "query-success",
        style: {
          fontFamily: "Poppins, sans-serif",
          fontSize: "14px",
        },
      });
    },
  }),
});
ReactDOM.render(
  <QueryClientProvider client={client}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </QueryClientProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
