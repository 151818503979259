import stringUtils from "./stringUtilities";

const newLine = /\r?\n/;
const defaultFieldDelimiter = ",";

class CsvToJson {
    formatValueByType(active) {
        this.printValueFormatByType = active;
        return this;
    }

    fieldDelimiter(delimieter) {
        this.delimiter = delimieter;
        return this;
    }

    parseSubArray(delimiter = '*', separator = ',') {
        this.parseSubArrayDelimiter = delimiter;
        this.parseSubArraySeparator = separator;
    }

    encoding(encoding) {
        this.encoding = encoding;
        return this;
    }

    csvStringToJson(csvString) {
        return this.csvToJson(csvString);
    }

    getHeaders(parsedCsv) {
        let lines = parsedCsv.split(newLine);
        let fieldDelimiter = this.getFieldDelimiter();
        let headers = lines[0].split(fieldDelimiter);
        return headers;
    }

    csvToJsonWithMappedHeaders(parsedCsv, mappedColumn) {
        let lines = parsedCsv.split(newLine);
        let fieldDelimiter = this.getFieldDelimiter();
        let headers = lines[0].split(fieldDelimiter);
        const columnsToMap = [];
        headers = headers.map(header => {
            if (mappedColumn.find(m => m.name === header && m.mappedColumn)) {
                columnsToMap.push(mappedColumn.find(m => m.name === header));
                return mappedColumn.find(m => m.name === header).mappedColumn;
            }
            return header;
        });
        let jsonResult = [];
        // const errorRows = [];
        for (let i = 1; i < lines.length; i++) {
            let currentLine = lines[i].split(fieldDelimiter);
            if (stringUtils.hasContent(currentLine)) {
                const result = this.buildJsonResult2(headers, currentLine, columnsToMap);
                jsonResult.push(result);
                // if (Object.keys(result.errors).some(key => result.errors[key])) {
                //     errorRows.push(i);
                // }
                // Object.keys(result.errors).forEach(key => {
                //     if (result.errors[key]) {
                //         columnsToMap.find(m => m.mappedColumn === key).errorRows.push(i - 1);
                //     }
                // })
                // if (result.errors["variant_sku"]) {
                //     errorRows.push(i - 1);
                // }
            }
        }
        return { json: jsonResult };
    }

    buildJsonResult2(headers, currentLine, columnsToMap) {
        const columnsToMapNames = columnsToMap.map(c => c.mappedColumn);
        let jsonObject = {};
        const errors = {};
        for (let j = 0; j < headers.length; j++) {
            if (columnsToMapNames.includes(headers[j])) {
                let propertyName = stringUtils.trimPropertyName(headers[j]);
                let value = currentLine[j];

                if (this.isParseSubArray(value)) {
                    value = this.buildJsonSubArray(value);
                }

                if (this.printValueFormatByType && !Array.isArray(value)) {
                    value = stringUtils.getValueFormatByType(currentLine[j]);
                }
                const col = columnsToMap.find(c => c.mappedColumn === headers[j]);
                let isInValid = false;
                if (col.validation) {
                    const validation = new RegExp(col.validation);;
                    isInValid = !validation.test(value)
                }
                jsonObject[propertyName] = value;
                errors[propertyName] = isInValid
            }
        }
        jsonObject.errors = errors;
        return jsonObject;
    }

    csvToJson(parsedCsv) {
        let lines = parsedCsv.split(newLine);
        let fieldDelimiter = this.getFieldDelimiter();
        let headers = lines[0].split(fieldDelimiter);

        let jsonResult = [];
        for (let i = 1; i < lines.length; i++) {
            let currentLine = lines[i].split(fieldDelimiter);
            if (stringUtils.hasContent(currentLine)) {
                jsonResult.push(this.buildJsonResult(headers, currentLine));
            }
        }
        return jsonResult;
    }

    getFieldDelimiter() {
        if (this.delimiter) {
            return this.delimiter;
        }
        return defaultFieldDelimiter;
    }

    buildJsonResult(headers, currentLine) {
        let jsonObject = {};
        for (let j = 0; j < headers.length; j++) {
            let propertyName = stringUtils.trimPropertyName(headers[j]);
            let value = currentLine[j];

            if (this.isParseSubArray(value)) {
                value = this.buildJsonSubArray(value);
            }

            if (this.printValueFormatByType && !Array.isArray(value)) {
                value = stringUtils.getValueFormatByType(currentLine[j]);
            }
            jsonObject[propertyName] = value;
        }
        return jsonObject;
    }

    buildJsonSubArray(value) {
        let extractedValues = value.substring(
            value.indexOf(this.parseSubArrayDelimiter) + 1,
            value.lastIndexOf(this.parseSubArrayDelimiter)
        );
        extractedValues.trim();
        value = extractedValues.split(this.parseSubArraySeparator);
        if (this.printValueFormatByType) {
            for (let i = 0; i < value.length; i++) {
                value[i] = stringUtils.getValueFormatByType(value[i]);
            }
        }
        return value;
    }

    isParseSubArray(value) {
        if (this.parseSubArrayDelimiter) {
            if (value && (value.indexOf(this.parseSubArrayDelimiter) === 0 && value.lastIndexOf(this.parseSubArrayDelimiter) === (value.length - 1))) {
                return true;
            }
        }
        return false;
    }

}

export default new CsvToJson();