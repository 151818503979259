import React, { useState, useEffect } from "react";
import useWindowSize from "../../Hooks/UI/useWindowSize";
import {
  FiImage,
  FiEdit2,
  FiChevronDown,
  FiLink,
  FiToggleRight,
} from "react-icons/fi";

const listener: any = [];

export const changeCursor = (type: string) => {
  listener.forEach((fn: any) => fn(type));
};

const CustomCursor: React.FC = () => {
  const size = useWindowSize();
  const [mousePosition, setMousePosition] = useState({
    x: 400,
    y: 400,
  });
  const [cursorType, setCursorType] = useState("");

  useEffect(() => {
    listener.push(setCursorType);
    return () => {
      listener.splice(listener.indexOf(setCursorType), 1);
    };
  }, []);

  const onMouseMove = (event: any) => {
    const { pageX, pageY } = event;
    setMousePosition({ x: pageX, y: pageY });
  };

  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);
    return () => {
      document.removeEventListener("mousemove", onMouseMove);
    };
  }, [size.width]);

  let cursor;
  switch (cursorType) {
    case "image":
      cursor = <FiImage className="w-5 h-5" />;
      break;
    case "pen":
      cursor = <FiEdit2 className="w-5 h-5" />;
      break;
    case "down":
      cursor = <FiChevronDown className="w-5 h-5" />;
      break;
    case "link":
      cursor = <FiLink className="w-5 h-5" />;
      break;
    case "toggle":
      cursor = <FiToggleRight className="w-5 h-5" />;
      break;
    default:
      cursor = "";
  }
  return (
    <>
      {cursor && (
        <div
          style={{ top: `${mousePosition.y}px`, left: `${mousePosition.x}px` }}
          className={`absolute z-50 text-indigo-600 font-bold -translate-x-7 -translate-y-7 pointer-events-none bg-white shadow-md p-1 rounded-full border`}>
          {cursor}
        </div>
      )}
    </>
  );
};

export default CustomCursor;
