import React, { memo } from "react";
import { VariableSizeGrid, areEqual } from "react-window";
import Field from "./TableFields/Fields";
import Cell from "./TableFields/TableData";

const TableGrid: React.FC<any> = ({
  gridRef,
  columns,
  gridWidth,
  style,
  allColumns,
  rows,
  prepareRow,
  type,
  props,
  isItemLoaded,
  rowSize,
  ...rest
}) => {
  // const resetScroll: any = {};
  // if (initialScroll) {
  //   resetScroll.initialScrollLeft = scrollLeftRef.current;
  // }
  return (
    <VariableSizeGrid
      className="bg-red"
      columnCount={Object.keys(columns).length}
      style={{ ...style }}
      columnWidth={index => {
        const columnn = Object.keys(columns)[index];
        return allColumns[columnn]?.width;
      }}
      height={type === "row" ? window.innerHeight - 125 : 50}
      rowCount={type === "row" ? rows.length : 1}
      rowHeight={index => (type === "row" ? parseInt(rowSize) : 50)}
      useIsScrolling
      ref={gridRef}
      width={gridWidth}
      {...rest}>
      {memo(({ columnIndex, rowIndex, style, isScrolling }) => {
        const columnn = Object.keys(columns)[columnIndex];
        const columnIndexx = allColumns.findIndex(
          (c: any) => c.id === columns[columnn].id
        );
        const column = allColumns[columnIndexx];

        const row = rows[rowIndex];
        prepareRow(row);
        const cell = row.cells[columnIndexx];

        if (type === "row") {
          return (
            <div className="p-1 text-xs font-sans text-gray-700 " style={style}>
              <div className="w-full h-full shadow rounded border border-gray-300">
                {column.id === "selection" ? (
                  <div className="w-full h-full flex items-center justify-center bg-indigo-900 text-white rounded">
                    {rowIndex + 1}
                  </div>
                ) : !isItemLoaded(rowIndex) ? (
                  <div className="w-full h-full flex items-center justify-center">
                    Loading...
                  </div>
                ) : isScrolling ? (
                  ""
                ) : (
                  cell.render((props: any) => {
                    return <Cell {...props} isScrolling={isScrolling} />;
                  })
                )}
              </div>
            </div>
          );
        } else {
          return (
            <div className="p-1 relative" style={style}>
              {column
                ? column.render((props: any) => <Field {...props} />)
                : null}
            </div>
          );
        }
      }, areEqual)}
    </VariableSizeGrid>
  );
};

export default TableGrid;
