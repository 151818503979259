import React from "react";
import { motion } from "framer-motion";
import "./spinner.css";

interface SpinnerProps {
  size?: "sm" | "md" | "lg";
}
const bounceTransition = {
  duration: 0.4,
  yoyo: Infinity,
  ease: "easeOut",
};

const Spinner: React.FC<SpinnerProps> = ({ size = "md" }) => {
  let sizeClass: string;
  if (size === "sm") {
    sizeClass = "w-2 h-2";
  } else if (size === "md") {
    sizeClass = "w-14 h-14";
  } else {
    sizeClass = "w-14 h-14";
  }

  return (
    <div className="flex justify-aroun">
      <motion.span
        className={`w-4 h-4 rounded-full ${sizeClass}`}
        transition={bounceTransition}
        animate={{
          y: ["100%", "-100%"],
          backgroundColor: ["rgb(83, 70, 227)", "rgb(229, 72, 156)"],
        }}
      />
    </div>
  );
};

export default Spinner;
