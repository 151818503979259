import React, { useMemo, useState, useEffect, memo } from "react";
import { CellProps } from "react-table";
import { changeCursor } from "../../../../../Components/CustomCursor";
import Color from "./Color";
import Image from "./Image";
import Select from "./Select";
import Selection from "./Selection";
import Status from "./Status";
import Switch from "./Switch";
import Text from "./Text";
import Url from "./Url";

export interface DataCellProps {
  value: any;
  isEditing: boolean;
  setValue: any;
  options: any;
  isMultivalued: boolean;
  statusColor: any;
}

const Cell: React.FC<CellProps<any>> = memo(props => {
  const {
    value: initialValue,
    column: {
      id: columnId,
      dataType,
      allowedValues,
      isMultivaluedAttribute,
      statusColor,
      isEditable,
    },
    row: { id: rowId },
    state,
    triggerOnMouseUp,
    triggerOnMouseDown,
    triggerOnMouseEnter,
    valueRef,
  } = props;

  const { selectedCells, activeCell, isEditing, isMouseDown, editedCell } =
    state;
  const [value, setValue] = useState(initialValue);

  const id = useMemo<string>(
    () => `${rowId}row__col${columnId}`,
    [rowId, columnId]
  );

  useEffect(() => {
    if (value !== initialValue) {
      valueRef.current = value;
    }
  }, [value, valueRef, initialValue]);

  const commonProps = useMemo<DataCellProps>(() => {
    return {
      value: value,
      isEditing: isEditing && activeCell === id && isEditable,
      setValue,
      options: allowedValues,
      isMultivalued: isMultivaluedAttribute,
      statusColor: statusColor,
    };
  }, [
    value,
    isEditing,
    activeCell,
    id,
    setValue,
    allowedValues,
    isMultivaluedAttribute,
    statusColor,
    isEditable,
  ]);

  let dataField = useMemo(() => {
    switch (dataType) {
      case "text":
      case "String":
      case "Int":
      case "Double":
        return {
          Component: <Text {...commonProps} />,
          cursorType: "pen",
        };
      case "Video":
      case "video":
      case "Image":
      case "image":
        return {
          Component: <Image {...commonProps} type={dataType} />,
          cursorType: "image",
        };
      case "enum":
      case "Enum":
        return {
          Component: <Select {...commonProps} />,
          cursorType: "down",
        };
      case "Bool":
      case "boolean":
        return {
          Component: <Switch {...commonProps} />,
          cursorType: "toggle",
        };
      case "Url":
        return {
          Component: <Url {...commonProps} />,
          cursorType: "link",
        };
      case "Color_Hex_Code":
        return {
          Component: <Color {...commonProps} />,
          cursorType: "pen",
        };
      case "Status":
        return {
          Component: <Status {...commonProps} />,
          cursorType: "",
        };
      case "selection":
        return {
          Component: <Selection index={parseInt(rowId) + 1} />,
          cursorType: "",
        };
      default:
        return {
          Component: (
            <span className="w-full h-full flex items-center justify-center p-1">
              {value}
            </span>
          ),
          cursorType: "",
        };
    }
  }, [dataType, commonProps, value, rowId]);

  let className: string = "";
  if (selectedCells[id]) {
    className += "bg-indigo-100";
  }
  if (id === activeCell) {
    className += " ring-2 ring-indigo-500";
  }
  if (id === activeCell && isEditing && isEditable) {
    className += " bg-gray-200";
  }
  if (editedCell[id]) {
    className += " bg-gray-200";
  }

  return (
    <div
      onContextMenu={e => e.preventDefault()}
      onMouseUp={() => {
        if (id !== activeCell || !isEditing) {
          triggerOnMouseUp();
        }
      }}
      onMouseEnter={() => {
        if (isEditable) {
          changeCursor(dataField.cursorType);
        }
        if (!isEditing && id !== activeCell && isMouseDown) {
          triggerOnMouseEnter(id);
        }
      }}
      onMouseLeave={() => changeCursor("")}
      onMouseDown={e => {
        if (e.buttons === 2) {
          console.log("hello");
        } else {
          if (!isEditing || id !== activeCell) {
            e.preventDefault();
            triggerOnMouseDown(id);
          }
        }
      }}
      className={`w-full h-full rounded relative ${className}`}>
      {dataField.Component}
    </div>
  );
});

export default Cell;
