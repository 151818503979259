import { Formik } from "formik";
import { FC, useState, useContext, createContext, ReactNode } from "react";
import { FormContent } from "./FormContents";
import { FormSubmitButtons } from "./FormSubmitButtons";
import { FormTab } from "./FormTab";
import { FormData } from "./types";

interface Props {
  data: FormData;
  children: ReactNode;
  submitHandler: (data: any) => void;
}

interface CompoundForm {
  Tabs: typeof FormTab;
  ActionButtons: typeof FormSubmitButtons;
  FormContent: typeof FormContent;
}

const formContext = createContext<any>({});

const getInitialValue = (formData: FormData, activeGroup: number) => {
  return formData.groups[activeGroup].components.reduce((acc, component) => {
    return { ...acc, [component.jsonName]: component.value };
  }, {});
};

export const Form: FC<Props> & CompoundForm = ({
  data,
  children,
  submitHandler,
}) => {
  const [activeGroup, setActiveGroup] = useState(0);
  return (
    <formContext.Provider
      value={{
        formData: data,
        activeGroup,
        setActiveGroup,
      }}>
      <Formik
        initialValues={getInitialValue(data, activeGroup)}
        onSubmit={values => {
          submitHandler(values);
        }}>
        <>{children}</>
      </Formik>
    </formContext.Provider>
  );
};

export const useFormContext = () => {
  return useContext(formContext);
};

Form.Tabs = FormTab;
Form.FormContent = FormContent;
Form.ActionButtons = FormSubmitButtons;
