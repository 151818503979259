import { FC } from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { useField } from "formik";

interface Props {
  label: string;
  name: string;
  multiline?: boolean;
  rows?: number;
}

export const TextInput: FC<Props> = ({
  name,
  label,
  multiline = false,
  rows = 3,
}) => {
  const [field, meta] = useField({ name });
  const { touched, error } = meta;
  let extraProps: TextFieldProps = {};
  if (multiline) {
    extraProps.multiline = true;
    extraProps.rows = rows;
  }
  const updatedField = { ...field };
  updatedField.value = updatedField.value ? updatedField.value : "";
  return (
    <TextField
      label={label}
      size="small"
      type="text"
      fullWidth
      {...extraProps}
      {...updatedField}
      error={touched.valueOf() && Boolean(error)}
      helperText={touched.valueOf() && error}
    />
  );
};
