import React from "react";
import Navigation from "../Navigation";
import Sidebar from "../Sidebar";
// import Navigation from "../Navigation";

interface LayoutProps {
  menus: any;
  position?: "left" | "right" | "top" | "bottom";
}

interface HeaderMenu {
  logoUrl: string;
}
interface BodyMenu {
  id: string;
  name: string;
  path: string;
  isParent: boolean;
  icon: React.ReactNode;
  children: BodyMenu[];
}

interface FooterMenu {
  email: string;
  firstname: string;
  id: number;
  lastname: string;
  mobile: string;
  profileImageUrl: string;
  role: string;
}

export interface MenuObject {
  header: HeaderMenu;
  body: BodyMenu[];
  footer: FooterMenu;
}

interface SvgIconProps {
  url: string;
}
const SvgIcon: React.FC<SvgIconProps> = ({ url }) => {
  return (
    <svg className="w-6 h-6">
      <image xlinkHref={url} />
    </svg>
  );
};

const getNestedMenus = (menus: any) => {
  return menus.map((menu: any) => {
    return {
      id: menu.token,
      name: menu.name,
      path: menu.route ? menu.route : "",
      isParent: menu.services ? true : false,
      icon: <SvgIcon url={menu.iconImageUrl} />,
      children: menu.services ? getNestedMenus(menu.services) : [],
    };
  });
};

const transformMenus: (menus: any) => any = menus => {
  const obj: any = {};
  obj.header = {
    logoUrl: menus.logoImageUrl,
  };
  obj.body = getNestedMenus(menus.apps);
  obj.footer = {
    email: menus.staff.email,
    firstname: menus.staff.firstname,
    id: menus.staff.id,
    lastname: menus.staff.lastname,
    mobile: menus.staff.mobile,
    profileImageUrl: menus.staff.profileImageUrl,
    role: menus.staff.role,
  };
  return obj;
};

const Layout: React.FC<LayoutProps> = ({
  children,
  menus,
  position = "left",
}) => {
  const transformedMenus = transformMenus(menus);

  return (
    <main className={`w-full h-full overflow-hidden`}>
      <aside style={{ zIndex: 1000 }} className="fixed left-0 top-0 bg-white">
        <Sidebar menus={transformedMenus} />
      </aside>
      <section>{children}</section>
    </main>
  );
};

export default Layout;
