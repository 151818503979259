import React, { useState, useEffect } from "react";
import { MenuObject } from "../Layout";
import { FiXCircle, FiChevronDown, FiLogOut } from "react-icons/fi";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Button } from "../Button";

interface SidebarProps {
  menus: MenuObject;
}

const listener: any[] = [];

export const toggleSideBar = () => {
  listener.forEach(fn => fn((p: any) => !p));
};

const Sidebar: React.FC<SidebarProps> = ({ menus }) => {
  const [open, setOpen] = useState(false);
  const [openState, setOpenState] = useState<boolean[]>(
    new Array(menus.body.length).fill(false)
  );
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    listener.push(setOpen);
    return () => {
      listener.splice(listener.indexOf(setOpen), 1);
    };
  }, []);

  const setOpenMenu = (index: number) => {
    setOpenState(p => {
      const newState = [...p];
      newState[index] = !p[index];
      return newState;
    });
  };

  const logOut = () => {
    localStorage.removeItem("token");
    history.replace("/signin");
    window.location.reload();
  };

  const getNestedMenus = (menus: any) => {
    return menus.map((menu: any, index: number) => {
      return (
        <li
          key={index}
          className="font-sans cursor-pointer rounded text-gray-900">
          {menu.isParent ? (
            <div
              className="flex items-center justify-between p-2"
              onClick={() => setOpenMenu(index)}>
              <div className="flex items-center space-x-2">
                <span>{menu.icon}</span>
                <span>{menu.name}</span>
              </div>
              <div>
                <FiChevronDown className="shadow bg-white text-indigo-700" />
              </div>
            </div>
          ) : (
            <Link to={menu.path}>
              <div
                className={`px-2 py-1 text-sm hover:text-indigo-700 rounded ${
                  location.pathname === menu.path ? "bg-white shadow" : ""
                }`}>
                <div className="flex items-center space-x-2">
                  <span>{menu.icon}</span>
                  <span>{menu.name}</span>
                </div>
              </div>
            </Link>
          )}
          {menu.isParent && openState[index] && (
            <ul className="bg-indigo-50 text-white shadow-inner p-2 flex flex-col space-y-1">
              {getNestedMenus(menu.children)}
            </ul>
          )}
        </li>
      );
    });
  };

  return (
    <>
      {open && (
        <>
          <div className="w-64 h-full flex flex-col space-y-10 fixed left-0 top-0 shadow-lg bg-white z-50">
            <div className="w-full h-auto flex items-center justify-between p-3 pb-0">
              <img
                src={menus.header.logoUrl}
                alt="logo_url"
                className="w-20 h-auto"
              />
              <div className="p-2 shadow-md rounded-md">
                <FiXCircle
                  onClick={() => setOpen(false)}
                  className="text-2xl text-indigo-700"
                />
              </div>
            </div>
            <div className="w-full flex-1 overflow-y-scroll p-3">
              <ul className="flex flex-col space-y-2">
                {getNestedMenus(menus.body)}
              </ul>
            </div>
            <div className="flex flex-col p-3 space-y-5 items-start">
              <div className="flex space-x-2">
                <div className="flex items-center justify-center">
                  <img
                    src={menus.footer.profileImageUrl}
                    alt="Profile Url"
                    className="w-10 h-10 rounded-full"
                  />
                </div>
                <div className="flex flex-col">
                  <div className="font-sans">
                    {menus.footer.firstname} {menus.footer.lastname}
                  </div>
                  <div className="font-sans text-gray-700 text-xs">
                    {menus.footer.email}
                  </div>
                </div>
              </div>
              <div className="shadow-md p-1 rounded-sm">
                <Button
                  variant="text"
                  size="xs"
                  icon={{ component: <FiLogOut />, position: "left" }}
                  onClick={logOut}>
                  Sign Out
                </Button>
              </div>
            </div>
          </div>
          <div
            onClick={() => setOpen(false)}
            className="fixed w-screen h-screen top-0 left-0"></div>
        </>
      )}
    </>
  );
};

export default Sidebar;
