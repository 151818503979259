import { FC } from "react";
import { Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useFormContext } from "../Form";
import { FormGroup } from "../types";

export const FormTab: FC = () => {
  const { formData, activeGroup, setActiveGroup } = useFormContext();
  return (
    <div>
      <Stepper activeStep={activeGroup}>
        {formData.groups.map((group: FormGroup, index: number) => (
          <Step
            sx={{ cursor: "pointer" }}
            key={group.id}
            onClick={() => setActiveGroup(index)}>
            <StepLabel>
              <Typography variant="h5">{group.title}</Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};
