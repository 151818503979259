import React, { useState, useCallback } from "react";
import {
  FiMoreVertical,
  FiChevronLeft,
  FiChevronRight,
  FiRefreshCcw,
  FiArrowDown,
  FiSquare,
  FiCheckSquare,
  FiArrowUp,
} from "react-icons/fi";
import debounce from "lodash.debounce";
import { useEffect } from "react";
import {
  Autocomplete,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Checkbox,
  TextField,
  IconButton,
} from "@mui/material";

interface FieldsProps {
  column: any;
}

const Field: React.FC<FieldsProps> = React.memo((props: any) => {
  const {
    Header,
    getColumnStickyPosition,
    id,
    stickyLeft,
    stickyRight,
    resetSticky,
    isSearchable,
    isSortable,
    aliasName,
    iconUrl,
    getResizerProps,
    isResizing,
    width,
    allowedValues,
    dataType,
  } = props.column;
  const {
    setFilterQuery,
    setSearchQuery,
    setSortOrder,
    middleColumnGridRef,
    middleRowGridRef,
  } = props;

  const [searchValue, setSearchValue] = useState<string>("");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeHandler = (event: any) => {
    setSearchValue(event.target.value);
  };
  const debouncedChangeHandler = useCallback(debounce(changeHandler, 500), []);

  useEffect(() => {
    if (isResizing) {
      const stickyPosition = getColumnStickyPosition(id);
      if (!stickyPosition) {
        middleColumnGridRef.current.resetAfterColumnIndex(parseInt(id));
        middleRowGridRef.current.resetAfterColumnIndex(parseInt(id));
      }
    }
  }, [
    isResizing,
    id,
    middleColumnGridRef,
    middleRowGridRef,
    getColumnStickyPosition,
    width,
  ]);

  const getListEle = () => {
    const stickyPosition = getColumnStickyPosition(id);
    if (stickyPosition) {
      return (
        <MenuItem onClick={() => resetSticky(id, stickyPosition)}>
          <ListItemIcon>
            <FiRefreshCcw />
          </ListItemIcon>
          <ListItemText>Reset Sticky</ListItemText>
        </MenuItem>
      );
    } else {
      return (
        <>
          <MenuItem onClick={() => stickyLeft(id)}>
            <ListItemIcon>
              <FiChevronLeft className="text-black" />
            </ListItemIcon>
            <ListItemText>Sticky Left</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => stickyRight(id)}>
            <ListItemIcon>
              <FiChevronRight className="text-black" />
            </ListItemIcon>
            <ListItemText>Sticky Right</ListItemText>
          </MenuItem>
        </>
      );
    }
  };
  const icon = <FiSquare className="text-lg" />;
  const checkedIcon = <FiCheckSquare className="text-lg" />;
  const getFilterEle = () => {
    if (dataType !== "Enum") {
      return (
        <TextField
          label="Search"
          placeholder="Search a value"
          size="small"
          onChange={debouncedChangeHandler}
        />
      );
    } else {
      return (
        <>
          <Autocomplete
            multiple
            onChange={(event: any, value: any) => {
              setSearchValue(value.map((el: any) => el.value).join(","));
            }}
            onClose={() => {}}
            size="small"
            id="checkboxes-tags-demo"
            options={allowedValues}
            disableCloseOnSelect
            getOptionLabel={(option: any) => option.label}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  size="small"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </li>
            )}
            style={{ width: 400 }}
            renderInput={params => (
              <TextField
                {...params}
                size="small"
                label="Checkboxes"
                placeholder="Select values"
              />
            )}
          />
        </>
      );
    }
  };

  return (
    <>
      <div className="w-full h-full flex items-center shadow justify-between px-1 border font-sans rounded-md backdrop-blur-md">
        {dataType !== "selection" && (
          <>
            <div className="flex-1 flex items-center overflow-hidden text-ellipsis cursor-pointer truncate space-x-1">
              {iconUrl && (
                <span>
                  <svg width="24px" height="24px">
                    <image xlinkHref={iconUrl} />
                  </svg>
                </span>
              )}
              <span className="flex-1 truncate overflow-hidden text-ellipsis">
                {Header}
              </span>
            </div>
            <IconButton onClick={handleClick} size="small">
              <FiMoreVertical className="text-black" />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}>
              {getListEle()}
              {isSortable && (
                <>
                  <MenuItem
                    onClick={(e: any) => setSortOrder(aliasName, "ASC")}>
                    <ListItemIcon>
                      <FiArrowDown />
                    </ListItemIcon>
                    <ListItemText>Sort ASC</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={(e: any) => setSortOrder(aliasName, "DESC")}>
                    <ListItemIcon>
                      <FiArrowUp />
                    </ListItemIcon>
                    <ListItemText>Sort DESC</ListItemText>
                  </MenuItem>
                </>
              )}
              {isSearchable && (
                <div className="p-4 pb-0">
                  <form
                    onSubmit={e => {
                      e.preventDefault();
                      if (dataType === "Enum") {
                        setFilterQuery(`${aliasName}:(${searchValue})`);
                      } else {
                        setSearchQuery(`${aliasName}:${searchValue}`);
                      }
                    }}>
                    {getFilterEle()}
                  </form>
                </div>
              )}
            </Menu>
          </>
        )}
      </div>
      {dataType !== "selection" && (
        <div
          {...getResizerProps()}
          className="absolute h-1/2 w-1 bg-indigo-100 hover:bg-indigo-700 right-0 top-1/4 translate-x-1/2"></div>
      )}
    </>
  );
});

export default Field;
