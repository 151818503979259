import React, { useState, useEffect } from "react";
import { FiVideo } from "react-icons/fi";
import {
  closeDropdown,
  openDropdown,
} from "../../../../../Components/Dropdown/index2";
import { withImage } from "../../../../../Components/HOC/withImage";
import { useClickOutside } from "../../../../../Hooks/utils/useClickOutside";

const Image: React.FC<any> = ({
  value,
  isEditing,
  fileInputRef,
  deleteImage,
  type,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [src, setSrc] = useState<any>("");

  useEffect(() => {
    if (value) {
      if (typeof value === "string") {
        setSrc(value);
      } else {
        let reader = new FileReader();
        reader.readAsDataURL(value);
        reader.onloadend = () => {
          setSrc(reader.result);
        };
      }
    }
    return () => {
      setSrc("");
    };
  }, [value]);

  const updateImage = () => {
    if (isEditing) {
      fileInputRef.current.click();
    }
  };

  useClickOutside(containerRef, closeDropdown);

  const getContent = () => {
    return (
      <ul className="list-none font-sans text-sm px-1">
        {isEditing && (
          <>
            <li
              className="p-1 hover:bg-indigo-700 hover:text-white rounded cursor-pointer"
              onClick={deleteImage}>
              Delete
            </li>
            <li
              className="p-1 hover:bg-indigo-700 hover:text-white rounded cursor-pointer"
              onClick={updateImage}>
              Update
            </li>
          </>
        )}
        {src && (
          <a href={src} target="_blank" rel="noreferrer">
            <li className="p-1 hover:bg-indigo-700 hover:text-white rounded cursor-pointer">
              Preview
            </li>
          </a>
        )}
      </ul>
    );
  };

  return (
    <div
      ref={containerRef}
      className="w-full h-full"
      onClick={() => {
        if (isEditing || src) {
          openDropdown(containerRef.current, getContent);
        }
      }}>
      {src &&
        (type === "Image" ? (
          <img className="object-contain p-1 w-full h-full" src={src} alt="" />
        ) : (
          <div className="w-full h-full flex justify-center items-center">
            <FiVideo className="text-lg text-indigo-700" />
          </div>
        ))}
    </div>
  );
};

export default withImage(Image);
