import { updateSticky } from "./../../../Utils/index";

const initialState = {
  left: {
    width: 0,
    columns: {},
  },
  middle: {
    width: 0,
    columns: {},
  },
  right: {
    width: 0,
    columns: {},
  },
};

const useStickyReducer = (
  state: any,
  action: any,
  prevState: any,
  instance: any
) => {
  const { columns } = instance;
  const updatedState = { ...state };

  switch (action.type) {
    case "init":
      return {
        ...state,
        ...initialState,
        middle: {
          columns: columns.reduce(
            (acc: any, c: any) => ({
              ...acc,
              [c.id]: c,
            }),
            {}
          ),
          width: window.innerWidth,
        },
      };
    case "STICKY_LEFT":
      return updateSticky(updatedState, action.payload.id, "ADD", "LEFT");
    case "STICKY_RIGHT":
      return updateSticky(updatedState, action.payload.id, "ADD", "RIGHT");
    case "RESET_STICKY":
      return updateSticky(
        updatedState,
        action.payload.id,
        "REMOVE",
        action.payload.currentPosition
      );
    default:
      return updatedState;
  }
};

const useColumns = (columns: any, { instance }: any) => {
  const { dispatch } = instance;
  const stickyLeft = (id: any) => {
    dispatch({ type: "STICKY_LEFT", payload: { id } });
  };
  const stickyRight = (id: any) => {
    dispatch({ type: "STICKY_RIGHT", payload: { id } });
  };
  const resetSticky = (id: any, currentPosition: any) => {
    console.log(id, currentPosition);
    dispatch({
      type: "RESET_STICKY",
      payload: { id, currentPosition },
    });
  };

  const getColumnStickyPosition = (id: any) => {
    const { left, right } = instance.state;
    if (left.columns[id]) return "LEFT";
    if (right.columns[id]) return "RIGHT";
    return null;
  };

  return columns.map((column: any) => {
    return {
      ...column,
      stickyLeft,
      stickyRight,
      resetSticky,
      getColumnStickyPosition,
    };
  });
};

export const useStickyColumns = (hooks: any) => {
  hooks.stateReducers.push(useStickyReducer);
  hooks.allColumns.push(useColumns);
};
