import React, { useRef, useState } from "react";
import Dropdown from "../../../../../Components/Dropdown";
import { useClickOutside } from "../../../../../Hooks/utils/useClickOutside";

interface SelectionProps {
  index: number;
}

const Selection: React.FC<SelectionProps> = ({ index }) => {
  const containerRef = useRef<any>(null);
  const [show, setShow] = useState(false);
  const closeDropdown = () => {
    setShow(false);
  };
  useClickOutside(containerRef, closeDropdown);
  return (
    <Dropdown
      referenceEle={
        <div className="w-full h-full flex items-center justify-center rounded-sm bg-indigo-900 text-gray-100">
          {index}
        </div>
      }
      fullWidth
      show={show}
      setShow={setShow}>
      <div ref={containerRef} className="flex flex-col space-y-2 items-start">
        <ul className="list-decimal font-sans text-sm h-auto p-1 w-full">
          <li className="p-1 flex hover:bg-indigo-700 text-sm hover:text-white rounded cursor-pointer">
            Delete Row
          </li>
        </ul>
      </div>
    </Dropdown>
  );
};

export default Selection;
