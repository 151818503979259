import { useRef, useState, MouseEvent, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import { FiCheckSquare, FiSquare } from "react-icons/fi";

import { DataCellProps } from ".";

const SelectCell: React.FC<DataCellProps> = ({
  options,
  isEditing,
  value,
  setValue,
  isMultivalued,
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const valueRef = useRef<{ label: string; value: string }[] | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const handleClick = () => {
    if (divRef.current) setAnchorEl(divRef.current);
  };

  useEffect(() => {
    if (isEditing) {
      handleClick();
    }
  }, [isEditing]);

  const handleClose = () => {
    setAnchorEl(null);
    if (valueRef.current) {
      const updatedValue = valueRef.current.reduce(
        (acc: string, el: any, index: number) => {
          if (index !== 0) {
            return acc + "," + el.value;
          }
          return el.value;
        },
        ""
      );
      setValue(updatedValue);
    }
  };
  const open = Boolean(anchorEl);

  const icon = <FiSquare className="text-lg" />;
  const checkedIcon = <FiCheckSquare className="text-lg" />;

  return (
    <>
      <div
        ref={divRef}
        className="w-full h-full flex flex-nowrap items-center space-x-1 truncate px-1">
        {value?.split(",").map((el: any) => (
          <Chip
            label={el}
            size="small"
            color="primary"
            sx={{ fontSize: "12px", p: 0 }}
          />
        ))}
      </div>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open && isEditing}
        anchorEl={anchorEl}
        onClose={handleClose}>
        <div className="p-4">
          <Autocomplete
            multiple={isMultivalued}
            onChange={(event: any, value: any) => {
              valueRef.current = value;
            }}
            size="small"
            id="checkboxes-tags-demo"
            options={options}
            defaultValue={value?.split(",").map((el: any) => ({
              label: el,
              value: el,
            }))}
            disableCloseOnSelect
            getOptionLabel={(option: any) => option.label}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  size="small"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </li>
            )}
            style={{ width: 400 }}
            renderInput={params => (
              <TextField
                {...params}
                size="small"
                label="Checkboxes"
                placeholder="Select values"
              />
            )}
          />
        </div>
      </Popover>
    </>
  );
};

export default SelectCell;
