import { TextField } from "@mui/material";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Button } from "../../Components/Button";
import Spinner from "../../Components/Spinner";

import { useUploadQuery } from "../../Hooks/networkCalls/useUploadQuery";

interface SignInProps {
  setIsLoggedIn: (isLoggedIn: boolean) => void;
}

const SignIn: React.FC<SignInProps> = ({ setIsLoggedIn }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      history.goBack();
    }
  }, [history]);

  const { mutateAsync: signInAsync, isLoading: isSignInLoading } =
    useUploadQuery({
      link: "/v6/admin/staff/login",
      httpMethod: "POST",
      meta: "Login",
      onSuccess: (data: any) => {
        localStorage.setItem("token", data.token);
        setIsLoggedIn(true);
        history.replace("/");
      },
      authType: "basic",
    });

  return (
    <>
      <section className="w-full">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <main className="p-4 col-span-2 w-full h-screen bg-gradient-to-r from-white via-white to-gray-100">
            <div className="w-3/4 mx-auto h-full flex flex-col items-start justify-center space-y-8">
              <div className="flex flex-col justify-star">
                <h1 className="text-5xl font-bold text-gray-900 xl:text-6xl">
                  Catalog Distribution Everywhere
                </h1>
              </div>
            </div>
          </main>
          <aside className="w-full h-screen flex items-center">
            <div className="w-4/5 mx-auto h-4/5 flex flex-col justify-center space-y-8">
              <div>
                <h4 className="font-bold text-2xl">Sign In</h4>
              </div>
              <div className="lg:flex-1 flex flex-col space-y-8">
                {isSignInLoading ? (
                  <div className="w-full h-full flex items-center">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <TextField
                      id="email"
                      label="Email"
                      onChange={e => setEmail(e.target.value)}
                      value={email}
                      placeholder="Enter your email"
                      type="text"
                    />
                    <TextField
                      id="password"
                      label="Password"
                      onChange={e => setPassword(e.target.value)}
                      value={password}
                      placeholder="Enter your password"
                      type="password"
                    />
                  </>
                )}
              </div>
              <div className="flex space-x-4">
                <Button
                  variant="primary"
                  size="lg"
                  type="submit"
                  onClick={() => signInAsync({ email, password })}>
                  Sign In
                </Button>
              </div>
            </div>
          </aside>
        </div>
      </section>
    </>
  );
};

export default SignIn;
