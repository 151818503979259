import { FC } from "react";
import { Stack } from "@mui/material";
import { TextInput } from "./TextInput";
import { Select } from "./Select";
import { FileInput } from "./FileInput";
import { CustomRating } from "./Rating";
import { useFormContext } from "../Form";
import { FormGroupComponent } from "../types";

export const FormContent: FC = () => {
  const { formData, activeGroup } = useFormContext();

  const getComponent = (currentComponent: FormGroupComponent) => {
    const { displayName, allowedValues, component, isMandatory, jsonName } =
      currentComponent;
    switch (component) {
      case "textInput":
        return <TextInput label={displayName} name={jsonName} />;
      case "textArea":
        return (
          <TextInput label={displayName} name={jsonName} multiline rows={5} />
        );
      case "searchSelect":
      case "standardSelect":
        return (
          <Select
            label={displayName}
            name={jsonName}
            multiple={component === "searchSelect" ? true : false}
            options={allowedValues.map((el: string) => ({
              name: el,
              value: el,
            }))}
          />
        );
      case "fileInput":
        return <FileInput label={displayName} name={jsonName} type="image" />;
      case "rating":
        return <CustomRating label={displayName} name={jsonName} />;
      case "date":
        return <TextInput label={displayName} name={jsonName} />;
      case "time":
        return <TextInput label={displayName} name={jsonName} />;
      case "color":
        return <TextInput label={displayName} name={jsonName} />;
      default:
        return null;
    }
  };
  return (
    <>
      {formData.groups[activeGroup].components.map(
        (item: FormGroupComponent, index: number) => {
          return <div key={index}>{getComponent(item)}</div>;
        }
      )}
    </>
  );
};
