export const getSelectedCells = (startCell: string, endCell: string) => {
  const startCellIndexes = startCell.split("row__col");
  const startRowIndex = startCellIndexes[0];
  const startColumnIndex = startCellIndexes[1];

  const endCellIndexes = endCell.split("row__col");
  const endRowIndex = endCellIndexes[0];
  const endColumnIndex = endCellIndexes[1];

  let rowStart, rowEnd, cellStart, cellEnd;
  if (endRowIndex < startRowIndex) {
    rowStart = endRowIndex;
    rowEnd = startRowIndex;
  } else {
    rowStart = startRowIndex;
    rowEnd = endRowIndex;
  }
  if (endColumnIndex < startColumnIndex) {
    cellStart = endColumnIndex;
    cellEnd = startColumnIndex;
  } else {
    cellStart = startColumnIndex;
    cellEnd = endColumnIndex;
  }
  const selectedArray: any = {};
  for (let i: any = rowStart; i <= rowEnd; i++) {
    for (let j: any = cellStart; j <= cellEnd; j++) {
      const cell = `${i}row__col${j}`;
      selectedArray[cell] = cell;
    }
  }
  return selectedArray;
};

export const updateSticky = (
  state: any,
  id: any,
  type: "ADD" | "REMOVE",
  position: "LEFT" | "RIGHT"
) => {
  const updatedState = { ...state };
  const updatedMiddle = { ...updatedState.middle };
  const updatedLeft = { ...updatedState.left };
  const updatedRight = { ...updatedState.right };

  const updatedMiddleColumn = { ...updatedMiddle.columns };
  let updatedStickyColumn: any;
  if (position === "LEFT") {
    updatedStickyColumn = { ...updatedLeft.columns };
  } else {
    updatedStickyColumn = { ...updatedRight.columns };
  }

  if (type === "ADD") {
    if (!updatedStickyColumn[id]) {
      if (updatedMiddleColumn[id]) {
        updatedStickyColumn[id] = updatedMiddleColumn[id];
        delete updatedMiddleColumn[id];
      }
    }
  } else {
    if (updatedStickyColumn[id]) {
      updatedMiddleColumn[id] = updatedStickyColumn[id];
      delete updatedStickyColumn[id];
    }
  }

  if (position === "LEFT") {
    updatedLeft.columns = updatedStickyColumn;
    updatedLeft.width = Object.keys(updatedStickyColumn).reduce(
      (acc: any, c: any) => acc + updatedStickyColumn[c].width,
      0
    );
  } else {
    updatedRight.columns = updatedStickyColumn;
    updatedRight.width = Object.keys(updatedStickyColumn).reduce(
      (acc: any, c: any) => acc + updatedStickyColumn[c].width,
      0
    );
  }
  updatedMiddle.columns = updatedMiddleColumn;
  updatedMiddle.width =
    window.innerWidth - updatedLeft.width - updatedRight.width;
  updatedState.left = updatedLeft;
  updatedState.middle = updatedMiddle;
  updatedState.right = updatedRight;
  return updatedState;
};

export const uploadFile = async (
  file: File,
  key: string | number | symbol
): Promise<{ key: string | number | symbol; value: string }> => {
  const formData = new FormData();
  formData.append("fileName", file);
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/v6/admin/file/upload`,
    {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  const data = (await response.json()) as {
    uploadResult: [
      {
        fields_name: string;
        content_type: string;
        file_name: string;
        file_size: number;
        public_url: string;
      }
    ];
  };
  return {
    key,
    value: data.uploadResult[0].public_url,
  };
};

// export const resetSticky = (nonSticky: any, newId: any) => {
//   let appended = false;
//   const updatedNonSticky: any = [];
//   nonSticky.forEach((c: any) => {
//     if (parseInt(c.id) > parseInt(newId) && appended === false) {
//       appended = true;
//       updatedNonSticky.push(updatedNonSticky.find((c: any) => c.id === newId));
//     }
//     updatedNonSticky.push(c);
//   });
//   if (appended === false) {
//     updatedNonSticky.push(updatedNonSticky.find((c: any) => c.id === newId));
//   }
//   return updatedNonSticky;
// };
