import React, { useRef, useMemo } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import {
  useTable,
  useRowSelect,
  useResizeColumns,
  useBlockLayout,
} from "react-table";
import Cell from "./TableFields/TableData";
import TableGrid from "./TableGrid";
import { useSelectCell } from "./useSelectCell";
import InfiniteLoader from "react-window-infinite-loader";

import { useStickyColumns } from "./useStickyColumn";
import { useKeyPress } from "./useKeyPress";

interface TableProps {
  columns: any[];
  rows: any[];
  actionCallbacks: {
    setData: (data: any) => void;
    submitUpdate: (data: any) => any;
    setSearchQuery: (data: string) => void;
    setFilterQuery: (data: string) => void;
    setSortOrder: (key: string, order: "ASC" | "DESC") => void;
  };
  infiniteLoaderProps: {
    hasNextPage: boolean;
    isNextPageLoading: boolean;
    loadNextPage: () => void;
  };
  rowSize?: number;
}

interface xyz {
  scrollLeft: any;
  scrollTop: any;
  scrollUpdateWasRequested: any;
}

export const callBack: any = [];

const Table: React.FC<TableProps> = ({
  columns,
  rows: data,
  actionCallbacks,
  infiniteLoaderProps: { hasNextPage, isNextPageLoading, loadNextPage },
  rowSize = 40,
}) => {
  const skipPageReset = useRef<any>(false);
  //row grid refs
  const leftRowGridRef = useRef<any>();
  const middleRowGridRef = useRef<any>();
  const rightRowGridRef = useRef<any>();
  const scrollLeftRef = useRef<any>(0);

  //column grid refs
  const leftColumnGridRef = useRef<any>();
  const middleColumnGridRef = useRef<any>();
  const rightColumnGridRef = useRef<any>();

  const defaultColumn = useMemo(
    () => ({
      maxWidth: Infinity,
      Cell: Cell,
    }),
    []
  );

  useEffect(() => {
    skipPageReset.current = false;
  }, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    state,
    rows,
    prepareRow,
    allColumns,
    saveData,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      skipPageReset,
      autoResetPage: true,
      middleColumnGridRef,
      middleRowGridRef,
      ...actionCallbacks,
    },
    useBlockLayout,
    useRowSelect,
    useResizeColumns,
    useStickyColumns,
    useSelectCell,
    useKeyPress
  );

  useEffect(() => {
    callBack.push(saveData);
    return () => {
      const index = callBack.indexOf(saveData);
      if (index > -1) {
        callBack.splice(index, 1);
      }
    };
  }, [data, saveData]);

  const { left, right, middle, activeCell } = state;

  const onGridScroll = useCallback(
    ({ scrollLeft, scrollTop, scrollUpdateWasRequested }: xyz) => {
      if (!scrollUpdateWasRequested) {
        scrollLeftRef.current = scrollLeft;
        middleColumnGridRef.current.scrollTo({
          scrollLeft: scrollLeft,
        });
        leftRowGridRef?.current?.scrollTo({
          scrollLeft: 0,
          scrollTop: scrollTop,
        });
        rightRowGridRef?.current?.scrollTo({
          scrollLeft: 0,
          scrollTop: scrollTop,
        });
      }
    },
    []
  );

  const commonGridProps = useMemo(() => {
    return {
      rows,
      prepareRow,
      allColumns,
    };
  }, [rows, prepareRow, allColumns]);

  const gridState = useMemo(() => {
    return {
      fields: [
        {
          type: "column",
          gridRef: leftColumnGridRef,
          columns: left.columns,
          gridWidth: left.width,
          style: {
            width: "auto",
            height: "auto",
            overflow: "hidden",
            paddingRight: "0.5rem",
          },
        },
        {
          type: "column",
          gridRef: middleColumnGridRef,
          columns: middle.columns,
          gridWidth: middle.width,
          style: { overflow: "hidden" },
        },
        {
          type: "column",
          gridRef: rightColumnGridRef,
          columns: right.columns,
          gridWidth: right.width,
          style: {
            width: "auto",
            height: "auto",
            overflow: "hidden",
            paddingRight: "1rem",
          },
        },
      ],
      data: [
        {
          type: "row",
          gridRef: leftRowGridRef,
          columns: left.columns,
          gridWidth: left.width,
          rowSize,
          useIsScrolling: true,
          style: {
            width: "auto",
            overflow: "hidden",
            paddingRight: "0.5rem",
          },
        },
        {
          type: "row",
          gridRef: middleRowGridRef,
          columns: middle.columns,
          gridWidth: middle.width,
          rowSize,
          useIsScrolling: true,
          onScroll: onGridScroll,
          style: { paddingRight: "0.5rem" },
        },
        {
          type: "row",
          gridRef: rightRowGridRef,
          columns: right.columns,
          gridWidth: right.width,
          rowSize,
          useIsScrolling: true,
          style: {
            width: "auto",
            overflow: "hidden",
          },
        },
      ],
    };
  }, [middle, left, right, onGridScroll, rowSize]);

  useEffect(() => {
    middleRowGridRef.current.resetAfterRowIndex(0);
    leftRowGridRef.current.resetAfterRowIndex(0);
    rightRowGridRef.current.resetAfterRowIndex(0);
  }, [rowSize]);

  useEffect(() => {
    if (activeCell) {
      const colRowIndex = activeCell.split("row__col");
      middleRowGridRef.current.scrollToItem({
        columnIndex: colRowIndex[1],
        rowIndex: colRowIndex[0],
      });
    }
  }, [activeCell]);

  const isItemLoaded = (index: any) => !hasNextPage || index < rows.length - 1;
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

  return (
    <div {...getTableProps()}>
      <div className="flex">
        {gridState.fields.map((field, index) => {
          return (
            <React.Fragment key={index}>
              <TableGrid {...commonGridProps} {...field} />
            </React.Fragment>
          );
        })}
      </div>
      <div className="bg-white" {...getTableBodyProps()}>
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={rows.length}
          loadMoreItems={loadMoreItems}
          threshold={1}>
          {({ onItemsRendered }) => {
            return (
              <div className="flex">
                {gridState.data.map((row, index: any) => {
                  const extraProps = {
                    onItemsRendered: ({
                      visibleRowStartIndex,
                      visibleRowStopIndex,
                      overscanRowStopIndex,
                      overscanRowStartIndex,
                    }: {
                      visibleRowStartIndex: any;
                      visibleRowStopIndex: any;
                      overscanRowStopIndex: any;
                      overscanRowStartIndex: any;
                    }) => {
                      onItemsRendered({
                        overscanStartIndex: overscanRowStartIndex,
                        overscanStopIndex: overscanRowStopIndex,
                        visibleStartIndex: visibleRowStartIndex,
                        visibleStopIndex: visibleRowStopIndex,
                      });
                    },
                  };
                  return (
                    <React.Fragment key={index}>
                      <TableGrid
                        {...commonGridProps}
                        {...row}
                        {...(index === 1 ? extraProps : {})}
                        isItemLoaded={isItemLoaded}
                      />
                    </React.Fragment>
                  );
                })}
              </div>
            );
          }}
        </InfiniteLoader>
      </div>
    </div>
  );
};

export default Table;
