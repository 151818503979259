import { useGetQuery } from "./useGetQuery";
import { useReducer, useEffect, useCallback } from "react";

interface GetTableData {
  tableId: string;
  link: string;
  meta: any;
}

interface ReducerState {
  page: { index: number; perPage: number };
  sort: { key: string; order: "ASC" | "DESC" };
  searchQuery: string;
  filterQuery: string;
  rowData: any[];
  totalRowCount: number;
  isLoading: boolean;
  hasNextPage: boolean;
}

interface SetPageAction {
  type: "SET_PAGE_INDEX";
  payload: { index: number };
}

interface SetSortAction {
  type: "SET_SORT";
  payload: { key: string; order: "ASC" | "DESC" };
}

interface SetTableDataAction {
  type: "SET_ROW_DATA";
  payload: { rowData: any[]; totalRowCount: number };
}

interface SetSearchQueryAction {
  type: "SET_SEARCH_QUERY";
  payload: { searchQuery: string };
}
interface SetFilterQueryAction {
  type: "SET_FILTER_QUERY";
  payload: { searchQuery: string };
}

interface SetLoading {
  type: "SET_LOADING";
  payload: { isLoading: boolean };
}

interface ResetRowData {
  type: "RESET_ROW_DATA";
}

type Action =
  | SetPageAction
  | SetSortAction
  | SetTableDataAction
  | SetSearchQueryAction
  | SetLoading
  | SetFilterQueryAction
  | ResetRowData;

const initialState: ReducerState = {
  page: { index: 1, perPage: 30 },
  sort: { key: "", order: "ASC" },
  searchQuery: "",
  filterQuery: "",
  rowData: [],
  totalRowCount: 0,
  isLoading: false,
  hasNextPage: true,
};

const tableDataReducer = (state: ReducerState, action: Action) => {
  const updatedState = { ...state };
  switch (action.type) {
    case "SET_PAGE_INDEX":
      const updatedPage = { ...state.page, index: action.payload.index };
      updatedState.page = updatedPage;
      break;
    case "SET_SORT":
      updatedState.rowData = [];
      updatedState.page = { index: 1, perPage: 40 };
      updatedState.totalRowCount = 0;
      const updatedSort = { ...state.sort, ...action.payload };
      updatedState.sort = updatedSort;
      break;
    case "SET_ROW_DATA":
      let transformedRowData = Object.keys(action.payload.rowData).map(
        (key: any) => action.payload.rowData[key]
      );
      transformedRowData = transformedRowData.map((ele: any) => {
        return ele.reduce(
          (acc: any, curr: any) => ({ ...acc, [curr.position]: curr.value }),
          {}
        );
      });
      const updatedRowData = [...updatedState.rowData, ...transformedRowData];
      updatedState.rowData = updatedRowData;
      updatedState.totalRowCount = action.payload.totalRowCount;
      updatedState.hasNextPage = !(
        state.rowData.length + 30 >
        action.payload.totalRowCount
      );
      break;
    case "SET_SEARCH_QUERY":
      updatedState.rowData = [];
      updatedState.page = { index: 1, perPage: 40 };
      updatedState.totalRowCount = 0;
      updatedState.searchQuery = action.payload.searchQuery;
      break;
    case "SET_FILTER_QUERY":
      updatedState.rowData = [];
      updatedState.page = { index: 1, perPage: 40 };
      updatedState.totalRowCount = 0;
      updatedState.filterQuery = action.payload.searchQuery;
      break;
    case "RESET_ROW_DATA":
      updatedState.rowData = [];
      updatedState.page = { index: 1, perPage: 40 };
      updatedState.totalRowCount = 0;
      break;
    case "SET_LOADING":
      updatedState.isLoading = action.payload.isLoading;
      break;
    default:
      return state;
  }
  return updatedState;
};

export const useGetTableData = <T>({ tableId, meta, link }: GetTableData) => {
  const [state, dispatch] = useReducer(tableDataReducer, initialState);

  const { data, refetch, isLoading } = useGetQuery<any>({
    queryKey: [
      tableId,
      state.page.index.toString(),
      state.page.perPage.toString(),
      state.searchQuery,
      state.sort.key,
      state.sort.order,
      state.filterQuery,
    ],
    link:
      link +
      `&page=${state.page.index}&q=${state.searchQuery}&sort_key=${state.sort.key}&sort_order=${state.sort.order}&fq=[${state.filterQuery}]`,
    isEnabled: true,
    meta,
  });

  useEffect(() => {
    dispatch({ type: "RESET_ROW_DATA" });
  }, [tableId]);

  useEffect(() => {
    if (data) {
      dispatch({
        type: "SET_ROW_DATA",
        payload: {
          rowData: data.result,
          totalRowCount: data.count,
        },
      });
    }
  }, [data]);

  const setPageIndex = useCallback((index: number) => {
    dispatch({
      type: "SET_PAGE_INDEX",
      payload: { index },
    });
  }, []);

  const setSearchQuery = useCallback((searchQuery: string) => {
    dispatch({
      type: "SET_SEARCH_QUERY",
      payload: { searchQuery },
    });
  }, []);

  const setFilterQuery = useCallback((searchQuery: string) => {
    dispatch({
      type: "SET_FILTER_QUERY",
      payload: { searchQuery },
    });
  }, []);

  const setSortOrder = useCallback((key: string, order: "ASC" | "DESC") => {
    dispatch({
      type: "SET_SORT",
      payload: { key, order },
    });
  }, []);

  return {
    rowData: state.rowData,
    refetch,
    isLoading: isLoading,
    pageIndex: state.page.index,
    searchQuery: state.searchQuery,
    sortKeyValue: state.sort,
    hasNextPage: state.hasNextPage,
    setPageIndex,
    setSearchQuery,
    setSortOrder,
    resetData: () => dispatch({ type: "RESET_ROW_DATA" }),
    setFilterQuery,
  };
};
