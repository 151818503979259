import { TableInstance } from "react-table";
import { useEffect } from "react";
import throttle from "lodash.throttle";
import { useCallback } from "react";

export const animate = (cb: () => void) => {
  let timeout = setTimeout(() => {
    const id = requestAnimationFrame(() => {
      cb();
      cancelAnimationFrame(id);
    });
    clearTimeout(timeout);
  });
};

const useSelectInstace = (instance: TableInstance) => {
  const { dispatch, state, columns, submitUpdate, data, setData } = instance;

  const { editedCell, activeCell, isEditing, selectedCells } = state;

  const saveData = useCallback(() => {
    if (Object.keys(editedCell).length > 0) {
      submitUpdate(editedCell).then((res: any) => {
        dispatch({ type: "RESET_EDITED_CELL" });
      });
    }
  }, [editedCell, submitUpdate, dispatch]);

  const copyHandler = useCallback(() => {
    const cellRowCol: number[] = activeCell
      .split("row__col")
      .map((el: string) => parseInt(el));
    const row: any = data[cellRowCol[0]];
    if (row && columns[cellRowCol[1]].isEditable) {
      const column = Object.keys(row)[cellRowCol[1]];
      const value = row[parseInt(column) - 1];
      const columnSet = new Set();
      const allSelctedCells = Object.keys(selectedCells).reduce(
        (acc: any, el: string) => {
          const rowCol = selectedCells[el].split("row__col");
          columnSet.add(rowCol[1]);
          return {
            ...acc,
            [rowCol[0]]: rowCol,
          };
        },
        {}
      );
      if (columnSet.size === 1) {
        dispatch({
          type: "SET_EDITED_CELL",
          payload: {
            editedCell: Object.keys(allSelctedCells).map(el =>
              allSelctedCells[el].join("row__col")
            ),
          },
        });
        setData((prev: any) => {
          return prev.map((row: any, index: number) => {
            if (
              parseInt(allSelctedCells[index.toString()]) &&
              index === parseInt(allSelctedCells[index.toString()][0])
            ) {
              return {
                ...prev[parseInt(allSelctedCells[index.toString()][0])],
                [allSelctedCells[index.toString()][1] - 1]: value,
              };
            }
            return row;
          });
        });
      }
    }
  }, [data, activeCell, selectedCells, setData, dispatch, columns]);

  const setActiveCell = useCallback(
    (id: string) => {
      animate(() => {
        dispatch({ type: "SET_ACTIVE_CELL", payload: { id } });
      });
    },
    [dispatch]
  );

  useEffect(() => {
    const handleKeyPress = (e: any) => {
      if (!isEditing) {
        const cellRowCol = activeCell?.split("row__col");
        switch (e.code) {
          case "ArrowDown":
            setActiveCell(
              `${parseInt(cellRowCol[0]) + 1}row__col${parseInt(cellRowCol[1])}`
            );
            break;
          case "ArrowUp":
            setActiveCell(
              `${
                parseInt(cellRowCol[0]) > 0 ? parseInt(cellRowCol[0]) - 1 : 0
              }row__col${cellRowCol[1]}`
            );
            break;
          case "ArrowRight":
            setActiveCell(
              `${parseInt(cellRowCol[0])}row__col${
                parseInt(cellRowCol[1]) < columns.length
                  ? parseInt(cellRowCol[1]) + 1
                  : cellRowCol[1]
              }`
            );
            break;
          case "ArrowLeft":
            setActiveCell(
              `${parseInt(cellRowCol[0])}row__col${
                parseInt(cellRowCol[1]) > 0
                  ? parseInt(cellRowCol[1]) - 1
                  : cellRowCol[1]
              }`
            );
            break;
          case "KeyS":
            if (e.ctrlKey) {
              e.preventDefault();
              saveData();
            }
            break;
          case "KeyC":
            if (e.ctrlKey) {
              copyHandler();
            }
            break;
          default:
            break;
        }
      }
    };
    const throttleChangeHandler = throttle(handleKeyPress, 200);
    window.addEventListener("keydown", throttleChangeHandler);
    return () => {
      window.removeEventListener("keydown", throttleChangeHandler);
    };
  }, [
    activeCell,
    isEditing,
    columns.length,
    dispatch,
    setActiveCell,
    copyHandler,
    saveData,
  ]);

  Object.assign(instance, {
    saveData,
  });
};

export const useKeyPress = (hooks: any) => {
  hooks.useInstance.push(useSelectInstace);
};
