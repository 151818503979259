import React, { useEffect, useState, useMemo, useCallback } from "react";
import converter from "./../../../Utils/CSVtoJSon/index";
import { FiChevronRight } from "react-icons/fi";
import { Button } from "../../../Components/Button";
import { CustomSelect } from "../../../Components/CustomSelect";

interface ImportDataProps {
  columns: any;
  csv: any;
  submitData: any;
}

const DataImporter: React.FC<ImportDataProps> = ({
  columns,
  csv,
  submitData,
}) => {
  const [importedDataColumns, setImportedDataColumns] = useState<any>();
  const [activeColumnIndex, setActiveColumnIndex] = useState<any>(0);

  const [allMapped, setAllMapped] = useState<boolean>(false);
  const [csvData, setCsvData] = useState<any>();

  useEffect(() => {
    if (
      importedDataColumns?.columns.length > 0 &&
      importedDataColumns.columns.filter((el: any) => el.mappedColumn)
        .length === columns.length
    ) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const { json } = converter.csvToJsonWithMappedHeaders(
          e.target.result,
          importedDataColumns.columns
        );
        setCsvData(json);
        setAllMapped(true);
      };
      reader.readAsText(csv);
    }
  }, [importedDataColumns, csv, columns]);

  //IF CSV FILE IS AVAILABLE GENERATE THE STATE FOR MAPPING THE COLUMNS
  useEffect(() => {
    if (csv) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const headers = converter.getHeaders(e.target.result);
        setImportedDataColumns({
          columns: headers.map((ele: any) => {
            return {
              name: ele,
              mappedColumn: "",
              validation: "",
              errorRows: [],
              errorMessage: "",
            };
          }),
        });
      };
      reader.readAsText(csv);
    }
  }, [csv]);

  const columnSchema = useMemo(() => {
    if (columns) {
      return columns.map((d: any, index: any) => {
        return {
          Header: d.displayName,
          accessor: d.fieldName,
          exportColumn: false,
          position: index,
          dataType: d.dataType,
          width: 150,
          isEditable: false,
          isValidationReqd: d.isValidationReqd,
          validationRegex: d.validationRegex,
          editedFields: [],
        };
      });
    }
  }, [columns]);

  const mapColumn = useCallback(() => {
    setImportedDataColumns((prev: any) => {
      const updatedColumns = [...prev.columns];
      const updatedActiveColumn = { ...updatedColumns[prev.activeIndex] };
      updatedActiveColumn.mappedColumn =
        columnSchema[activeColumnIndex].accessor;

      if (columnSchema[activeColumnIndex].isValidationReqd) {
        updatedActiveColumn.validation =
          columnSchema[activeColumnIndex].validationRegex;
        updatedActiveColumn.errorMessage = "Something went wrong";
      }
      updatedColumns[prev.activeIndex] = updatedActiveColumn;
      return {
        ...prev,
        columns: updatedColumns,
      };
    });
    setActiveColumnIndex((prev: any) =>
      prev + 1 < columnSchema.length ? prev + 1 : prev
    );
  }, [activeColumnIndex, columnSchema]);

  useEffect(() => {
    const keyDownHandler = (e: any) => {
      if (e.keyCode === 13) {
        mapColumn();
      }
    };
    window.addEventListener("keydown", keyDownHandler);
    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, [mapColumn]);

  const changeActiveColumn = (index: any) => {
    const columnName = columnSchema[index].accessor;
    const mappedColumnIndex = importedDataColumns.columns.findIndex(
      (el: any) => el.mappedColumn === columnName
    );
    setImportedDataColumns((prev: any) => ({
      ...prev,
      activeIndex: mappedColumnIndex,
    }));
    setActiveColumnIndex(index);
  };
  console.log(importedDataColumns);
  return (
    <>
      {csv && importedDataColumns?.columns?.length > 0 && (
        <div className="w-full h-full p-5">
          {!allMapped && (
            <React.Fragment>
              <div className="grid grid-cols-3 gap-5 items-center mb-2">
                <div className="text-xl font-sans">Required Column Names</div>
                <div className="text-xl font-sans">Imported Column Names</div>
              </div>
              <div className="grid grid-cols-3 gap-5 mb-5">
                <div className="font-sans px-2 bg-indigo-900 rounded text-white flex items-center">
                  {columnSchema[activeColumnIndex].Header}
                </div>
                <div className="font-sans rounded text-white flex items-center">
                  <CustomSelect
                    label=""
                    name=""
                    onChange={(data: any) => {
                      setImportedDataColumns((prev: any) => ({
                        ...prev,
                        activeIndex: data.value,
                      }));
                    }}
                    value={
                      importedDataColumns?.columns[
                        importedDataColumns.activeIndex
                      ]
                    }
                    options={importedDataColumns?.columns.map(
                      (curr: any, index: any) => ({
                        name: curr.name,
                        value: index,
                      })
                    )}
                    multiple={false}
                  />
                </div>
                <div>
                  <Button
                    variant="secondary"
                    onClick={mapColumn}
                    icon={{
                      component: <FiChevronRight className="w-6 h-6" />,
                      position: "left",
                    }}
                  />
                </div>
              </div>
              {!allMapped && (
                <div className="w-full h-auto p-3 rounded shadow-inner grid grid-cols-3 gap-2">
                  {importedDataColumns.columns.map((col: any, index: any) => {
                    if (col.mappedColumn) {
                      return (
                        <div
                          key={index}
                          onClick={() => changeActiveColumn(index)}
                          className="font-sans p-2 shadow bg-white rounded flex items-center">
                          {
                            columns.find(
                              (ele: any) => ele.fieldName === col.mappedColumn
                            )?.displayName
                          }{" "}
                          = {col.name}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              )}
            </React.Fragment>
          )}
          {csvData && allMapped && (
            <>
              <div className="w-full h-5/6 overflow-scroll">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      {columnSchema.map((ele: any) => (
                        <th
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          key={ele.Header}>
                          {ele.Header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {csvData.slice(0, 20).map((row: any, index: any) => {
                      return (
                        <tr key={index}>
                          {columnSchema.map((ele: any) => (
                            <td
                              className="px-6 py-4 whitespace-nowrap text-xs font-sans"
                              key={ele.Header}>
                              {row[ele.accessor]}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="mt-3">
                <Button variant="secondary" onClick={() => submitData(csvData)}>
                  Submit
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default DataImporter;
