import { TableInstance } from "react-table";
import { getSelectedCells } from "./../../../Utils/index";
import { useRef } from "react";

const initalState = {
  isMouseDown: false,
  selectedCells: {},
  prevCell: null,
  activeCell: null,
  isEditing: false,
  lastEnteredCell: null,
  editedCell: {},
};

let isClicked: boolean;
const useSelectReducer = (state: any, action: any) => {
  const updatedState = { ...state };
  switch (action.type) {
    case "init":
      return {
        ...state,
        ...initalState,
      };
    case "ON_MOUSE_DOWN":
      if (
        !updatedState.isEditing ||
        action.payload.id !== updatedState.activeCell
      ) {
        if (isClicked) {
          updatedState.isEditing = true;
        } else {
          updatedState.isEditing = false;
        }
        if (action.payload.isEdited) {
          updatedState.editedCell[updatedState.activeCell] =
            updatedState.activeCell;
        }
        isClicked = true;
        const timeOut = setTimeout(() => {
          isClicked = false;
          clearTimeout(timeOut);
        }, 300);
        updatedState.isMouseDown = true;
        updatedState.selectedCells = {};
        if (!updatedState.prevCell) {
          updatedState.prevCell = action.payload.id;
        } else {
          updatedState.prevCell = updatedState.activeCell;
        }
        updatedState.activeCell = action.payload.id;
      }
      return updatedState;
    case "ON_MOUSE_UP":
      updatedState.isMouseDown = false;
      return updatedState;
    case "ON_MOUSE_ENTER":
      if (updatedState.isMouseDown && !updatedState.isEditing) {
        const updatedSelectedCells = getSelectedCells(
          updatedState.activeCell,
          action.payload.id
        );
        updatedState.selectedCells = updatedSelectedCells;
        return updatedState;
      }
      break;
    case "CHANGE__VALUE":
      updatedState.value = action.payload.value;
      return updatedState;
    case "SET_EDITED_CELL":
      if (typeof action.payload.editedCell === "object") {
        action.payload.editedCell.forEach((element: any) => {
          updatedState.editedCell[element] = element;
        });
      } else {
        updatedState.editedCell[action.payload.editedCell] =
          action.payload.editedCell;
      }
      updatedState.isEditing = false;
      return updatedState;
    case "SET_ACTIVE_CELL":
      updatedState.activeCell = action.payload.id;
      return updatedState;
    case "RESET_EDITED_CELL":
      updatedState.editedCell = {};
      return updatedState;
    default:
      return updatedState;
  }
};

const useSelectInstace = (instance: TableInstance) => {
  const {
    dispatch,
    setData,
    state,
    skipPageReset,
  }: { state: any; dispatch: any; setData: any; skipPageReset: any } = instance;

  const valueRef = useRef<any>(null);
  const { prevCell } = state;

  const onChangeCellValue = (id: any, value: any) => {
    skipPageReset.current = true;
    const [rowId, columnId] = id.split("row__col");
    setData((old: any) => {
      return old.map((row: any, index: any) => {
        if (index === parseInt(rowId)) {
          return {
            ...old[parseInt(rowId)],
            [parseInt(columnId) - 1]: value,
          };
        }
        return row;
      });
    });
  };

  const triggerOnMouseDown = (id: any) => {
    let isEdited = false;
    if (
      prevCell &&
      (valueRef.current !== null || valueRef.current === undefined)
    ) {
      console.log(valueRef.current);
      isEdited = true;
      onChangeCellValue(prevCell, valueRef.current);
      valueRef.current = null;
    } else {
      valueRef.current = null;
    }
    dispatch({ type: "ON_MOUSE_DOWN", payload: { id, isEdited } });
  };
  const triggerOnMouseEnter = (id: any) => {
    dispatch({ type: "ON_MOUSE_ENTER", payload: { id } });
  };
  const triggerOnMouseUp = (id: any) => {
    dispatch({ type: "ON_MOUSE_UP" });
  };

  Object.assign(instance, {
    triggerOnMouseDown,
    triggerOnMouseEnter,
    triggerOnMouseUp,
    onChangeCellValue,
    valueRef,
  });
};

export const useSelectCell = (hooks: any) => {
  hooks.stateReducers.push(useSelectReducer);
  hooks.useInstance.push(useSelectInstace);
};
