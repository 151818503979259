import React, { useMemo } from "react";
import Layout from "../Components/Layout";
import TokenBasedTable from "./TokenBasedTable";
import { Route } from "react-router-dom";
import { Menu, Menus, Services } from "./../types/components";

interface PageProps {
  menus: Services;
}

const createFlatList = (menus: Menus[], newMenus: Menu[]) => {
  menus.forEach((menu: any) => {
    newMenus.push(menu);
    if (menu.services) {
      createFlatList(menu.services, newMenus);
    }
  });
};

const Pages: React.FC<PageProps> = ({ menus: loadedMenu }) => {
  const menus: Menu[] = useMemo(() => {
    if (loadedMenu) {
      const flatMenu: Menu[] = [];
      createFlatList(Object.values(loadedMenu.apps), flatMenu);
      return flatMenu;
    } else {
      return [];
    }
  }, [loadedMenu]);
  return (
    <>
      {menus.length > 0 && (
        <Layout menus={loadedMenu}>
          {menus.map((menu: any, index: number) => {
            if (!menu.services) {
              if (menu.componentType === "DATATABLE") {
                return (
                  <Route exact key={index} path={menu.route}>
                    <TokenBasedTable menu={menu} />
                  </Route>
                );
              } else {
                return null;
              }
            } else {
              return null;
            }
          })}
        </Layout>
      )}
    </>
  );
};

export default Pages;
