import React from "react";
import { DataCellProps } from ".";

const Switch: React.FC<DataCellProps> = ({ value, isEditing, setValue }) => {
  return (
    <>
      <div
        onClick={() => {
          setValue((p: any) => (p === "1" ? "0" : "1"));
        }}
        className="w-full h-full flex justify-center items-center">
        <div
          className={`flex w-10 h-6 p-1 rounded-full ${
            value === "1"
              ? "bg-indigo-700 justify-end"
              : "bg-gray-400 justify-start"
          }`}>
          <div className="w-4 h-full bg-white rounded-full"></div>
        </div>
      </div>
    </>
  );
};

export default Switch;
