import React, { useMemo, useState, useCallback } from "react";
import { useEffect } from "react";
import { toggleSideBar } from "../../Components/Sidebar";
import Spinner from "../../Components/Spinner";
import { useGetQuery } from "../../Hooks/networkCalls/useGetQuery";
import { useGetTableData } from "../../Hooks/networkCalls/useGetTableData";
import { useUploadQuery } from "../../Hooks/networkCalls/useUploadQuery";
import { Menu } from "./../../types/components";
import Table from "./Table";
import { FiX, FiMenu, FiSave } from "react-icons/fi";
import CreateProduct from "./CreateProduct";
import CsvImport from "./CsvImport";
import MenuOption from "./MenuOption";
import ExportData from "./ExportData";
import { callBack } from "./Table";
import RowResize from "./RowResize";
import { useQueryClient } from "react-query";

interface TableProps {
  menu: Menu;
}

const TokenBasedTable: React.FC<TableProps> = ({
  menu: {
    retrieveConfigRoute,
    retrieveApiRoute,
    componentToken,
    updateApiRoute,
    updateHttpMethod,

    exportPath,

    createConfigRoute,
    createApiRoute,
    createHttpMethod,
    isCreateRequired,

    isBulkUpdateRequired,
    bulkUpdateConfigRoute,
    bulkUpdateApiRoute,
    bulkUpdateHttpMethod,

    name,
  },
}) => {
  const queryClient = useQueryClient();

  const [data, setData] = useState<any>([]);
  const [rowSize, setRowSize] = useState(40);
  const { data: tableFields, isLoading: getTableConfigLoading } =
    useGetQuery<any>({
      queryKey: ["tableFields", componentToken],
      link: retrieveConfigRoute,
      meta: name + " Fields",
      isEnabled: true,
      onSuccess: () => {},
    });

  const {
    rowData,
    isLoading: getTableDataLoading,
    pageIndex,
    searchQuery,
    sortKeyValue,
    setPageIndex,
    setFilterQuery,
    setSearchQuery,
    setSortOrder,
    hasNextPage,
    resetData,
  } = useGetTableData<any>({
    tableId: componentToken,
    link: retrieveApiRoute,
    meta: `${name} Data`,
  });

  const { mutateAsync: updateApiMutateAsync } = useUploadQuery({
    link: updateApiRoute,
    httpMethod: updateHttpMethod,
    meta: `${name} Updated`,
    onSuccess: () => {},
  });

  useEffect(() => {
    if (rowData.length > 0) {
      setData(rowData);
    }
  }, [rowData]);

  const columns = useMemo(() => {
    if (tableFields) {
      const filteredOptions = tableFields.fields.filter(
        (d: any) => d.isDisplayed
      );
      const sortedFilterOptions = filteredOptions.sort(function (
        a: any,
        b: any
      ) {
        return parseInt(a.position) - parseInt(b.position);
      });
      const schema = sortedFilterOptions?.map((column: any, index: number) => {
        return {
          //mandatory from react table
          Header: column.displayTitle,
          accessor: column.position.toString(),
          id: (index + 1).toString(),

          //mandatory for our need
          dataType: column.dataType,
          fieldName: column.fieldName,

          //optional
          iconUrl: column.headerIconImageUrl,
          aliasName: column.aliasName,
          isSearchable: column.isSearchable,
          isSortable: column.isSortable,
          isEditable: column.isEditable,
          isMultivaluedAttribute: column.isMultivalued,
          allowedValues: column.allowedValues
            ? column.allowedValues
                .split(",")
                .map((d: any) => ({ label: d.trim(), value: d.trim() }))
            : [],
          resizable: true,
          exportColumn: false,
          width: column.columnSize,
          position: column.position,
          statusColor: column.statusColor,
        };
      });
      schema.unshift({
        Header: "",
        accessor: "",
        dataType: "selection",
        resizable: false,
        id: "0",
        width: 40,
      });
      return schema;
    } else {
      return [];
    }
  }, [tableFields]);

  const submitUpdate = useCallback(
    changedCellIds => {
      const rows = new Set();
      const promises: any = [];
      Object.keys(changedCellIds).forEach((id: string) => {
        rows.add(parseInt(id.split("row__col")[0]));
      });
      const changedRows = Array.from(rows).map((row: any) => ({
        ...data[parseInt(row)],
      }));
      Array.from(rows).forEach((row: any, index) => {
        const changedRow = { ...data[parseInt(row)] };
        Object.keys(changedRow).forEach((col: string) => {
          const value = changedRow[col];
          if (value instanceof File) {
            const formData = new FormData();
            formData.append("fileName", data[row][parseInt(col)]);
            promises.push(
              fetch(`${process.env.REACT_APP_API_URL}/v6/admin/file/upload`, {
                method: "POST",
                body: formData,
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              })
                .then(res => res.json())
                .then(resData => {
                  changedRow[col] = resData.uploadResult.map(
                    (file: any) => file?.public_url
                  )[0];
                  changedRows[index] = changedRow;
                  return true;
                })
                .catch(err => {
                  return false;
                })
            );
          }
        });
      });
      return Promise.all(promises).then(values => {
        if (values.every(value => value)) {
          const responseBody = {
            objects: changedRows.map(row => {
              return Object.keys(row).reduce((acc, curr) => {
                return {
                  ...acc,
                  [columns[parseInt(curr) + 1].fieldName]:
                    row[curr] instanceof Array
                      ? row[curr].join(",")
                      : row[curr],
                };
              }, {});
            }),
          };
          return updateApiMutateAsync(responseBody)
            .then(res => {
              // resetData();
              // queryClient.invalidateQueries(componentToken);
              return res;
            })
            .catch(err => {
              console.log(err);
            });
        }
      });
    },
    [columns, updateApiMutateAsync, data]
  );

  const loadNextPage = useCallback(() => {
    setPageIndex(pageIndex + 1);
  }, [pageIndex, setPageIndex]);

  if ((getTableConfigLoading || getTableDataLoading) && pageIndex === 1) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <Spinner />
      </div>
    );
  }
  return (
    <div className="w-screen h-screen overflow-scroll flex flex-col space-y-2">
      <header className="w-full h-14 flex space-x-4 bg-white items-center p-2">
        <div className="flex items-center space-x-5">
          <div className="shadow-md bg-gradient-to-br from-indigo-600 to-pink-500 p-2 rounded-full">
            <FiMenu
              onClick={() => toggleSideBar()}
              className="text-2xl text-white"
            />
          </div>
          <h1 className="font-sans text-2xl">{name}</h1>
        </div>
        <div className="flex-1 flex justify-end items-center space-x-4 px-2">
          {sortKeyValue.key && (
            <MenuOption
              icon={<FiX />}
              label={`${sortKeyValue.key} : ${sortKeyValue.order}`}
              onClick={() => setSortOrder("", "ASC")}
            />
          )}
          {searchQuery && (
            <MenuOption
              icon={<FiX />}
              label={`${searchQuery.split(":")[0]} : ${
                searchQuery.split(":")[1]
              }`}
              onClick={() => setSearchQuery("")}
            />
          )}
          <MenuOption
            icon={<FiSave />}
            label={"Save"}
            onClick={() => callBack.forEach((d: any) => d())}
          />
          <RowResize setRowSize={setRowSize} />

          {isBulkUpdateRequired &&
            bulkUpdateConfigRoute &&
            bulkUpdateApiRoute &&
            bulkUpdateHttpMethod && (
              <CsvImport
                bulkUpdateConfigRoute={bulkUpdateConfigRoute}
                bulkUpdateApiRoute={bulkUpdateApiRoute}
                bulkUpdateHttpMethod={bulkUpdateHttpMethod}
                tableToken={componentToken}
              />
            )}

          {
            <ExportData
              exportApiRoute={exportPath}
              tableToken={componentToken}
              searchQuery={searchQuery}
            />
          }

          {createConfigRoute &&
            createApiRoute &&
            createHttpMethod &&
            isCreateRequired && (
              <CreateProduct
                createConfigRoute={createConfigRoute}
                createApiRoute={createApiRoute}
                createHttpMethod={createHttpMethod}
                tableToken={componentToken}
                resetData={resetData}
              />
            )}
        </div>
      </header>
      <section>
        {data.length > 0 && columns && (
          <Table
            columns={columns}
            rows={data}
            rowSize={rowSize}
            actionCallbacks={{
              setData,
              submitUpdate,
              setSearchQuery,
              setFilterQuery,
              setSortOrder,
            }}
            infiniteLoaderProps={{
              hasNextPage: hasNextPage,
              isNextPageLoading: getTableDataLoading,
              loadNextPage,
            }}
          />
        )}
      </section>
    </div>
  );
};

export default TokenBasedTable;
