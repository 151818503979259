import React from "react";
import { DataCellProps } from ".";

const Color: React.FC<DataCellProps> = ({ value }) => {
  return (
    <div
      style={{
        backgroundColor: value,
        fontFamily: "monospace",
        color: "#fff",
      }}
      className="w-full h-full flex items-center justify-center rounded">
      {value}
    </div>
  );
};

export default Color;
