import { TextField } from "@mui/material";
import React, { useState, useRef, useCallback } from "react";
import { FiDownload } from "react-icons/fi";
import { Button } from "../../../Components/Button";
import Dropdown from "../../../Components/Dropdown";
import { useGetQuery } from "../../../Hooks/networkCalls/useGetQuery";
import { useClickOutside } from "../../../Hooks/utils/useClickOutside";
import MenuOption from "../MenuOption";

interface ExportDataProps {
  exportApiRoute: string;
  tableToken: string;
  searchQuery: string;
}

const ExportData: React.FC<ExportDataProps> = ({
  exportApiRoute,
  tableToken,
  searchQuery,
}) => {
  const containerRef = useRef<any>(null);
  const [show, setShow] = useState(false);
  const [input, setInput] = useState("");

  const toggleShow = useCallback(() => {
    setShow(!show);
  }, [show]);
  useClickOutside(containerRef, toggleShow);

  const { refetch: getExportData } = useGetQuery({
    queryKey: ["exportPath", tableToken],
    link:
      exportApiRoute +
      `&name=${input}` +
      `&q=${searchQuery ? searchQuery : ""}`,
    isEnabled: false,
    meta: "Exported Successfully",
  });

  return (
    <Dropdown
      referenceEle={
        <MenuOption
          icon={<FiDownload />}
          label={"Export"}
          onClick={() => setShow(p => !p)}
        />
      }
      show={show}
      setShow={() => {}}>
      <div
        ref={containerRef}
        className="p-2 flex flex-col space-y-2 items-start">
        <TextField
          value={input}
          onChange={e => setInput(e.target.value)}
          id="exportText"
          placeholder="Enter a file name"
          type="text"
          label="Export file Name:"
        />
        <Button variant="secondary" onClick={getExportData} size="xs">
          Submit
        </Button>
      </div>
    </Dropdown>
  );
};

export default ExportData;
