import { Box, Card, CardActions, CardContent, CardHeader } from "@mui/material";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { Form } from "./Form";
import { FormData as FD } from "./Form/types";

const uploadFile = (file: File, key: string | number | symbol) => {
  const formData = new FormData();
  formData.append("fileName", file);
  return fetch(
    `https://swiftlyapps.api.jumkey.com/v5/listings/items/review/upload_image`,
    {
      method: "POST",
      body: formData,
    }
  )
    .then(res => res.json())
    .then(resData => {
      return {
        key,
        value: resData.uploadResult[0].public_url,
      };
    })
    .catch(err => {
      return false;
    });
};

const BrandImageUpload = () => {
  const history = useHistory();
  const [formData, setFormData] = useState<FD | null>(null);

  const getData = async () => {
    try {
      const response = await fetch(
        "https://api.mpowerapp.com/v6/admin/database/form/configuration?token=8277dd4a547242e3bda84431117c772e",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setFormData(data);
      }
    } catch (err: any) {
      console.log(err);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const submitHandler = (values: any) => {
    const promise: Promise<any>[] = [];
    const id = toast.loading("Submitting the form.");
    (Object.keys(values) as Array<keyof typeof values>).forEach(key => {
      if (values[key] instanceof File) {
        promise.push(uploadFile(values[key], key));
      } else if (values[key] instanceof Array) {
        values[key] = values[key].map((e: any) => e.value.trim()).join(",");
      }
    });
    Promise.all(promise)
      .then(async data => {
        const updatedValues = { ...values };
        data.forEach(d => {
          if (updatedValues[d.key] && updatedValues[d.key] instanceof File)
            updatedValues[d.key] = d.value;
        });
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}${formData!.apiRoute}`,
          {
            method: "POST",
            body: JSON.stringify(updatedValues),
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        await response.json();
        toast.success("Submitted");
        toast.remove(id);
      })
      .catch(err => {
        toast.error(err.message);
      });
  };

  if (!localStorage.getItem("token")) {
    history.push("/signin");
  }

  return (
    <>
      {formData && (
        <Card>
          <Form data={formData} submitHandler={submitHandler}>
            <CardHeader title={<Form.Tabs />} />
            <CardContent>
              <Box sx={{ pt: 1 }}>
                <Form.FormContent />
              </Box>
            </CardContent>
            <CardActions>
              <Form.ActionButtons />
            </CardActions>
          </Form>
        </Card>
      )}
    </>
  );
};

export default BrandImageUpload;
