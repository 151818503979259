import { FC } from "react";
import { Tab, Tabs } from "@mui/material";
import { useFormContext } from "../Form";
import { FormGroup } from "../types";

export const FormTab: FC = () => {
  const { formData, activeGroup, setActiveGroup } = useFormContext();
  return (
    <Tabs
      value={activeGroup}
      onChange={(e, newValue) => setActiveGroup(newValue)}>
      {formData.groups.map((group: FormGroup) => (
        <Tab key={group.id} label={group.title} />
      ))}
    </Tabs>
  );
};
