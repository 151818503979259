import { useQuery } from "react-query";
import { runBigTask } from "./../exposeWorker";
import { toast } from "react-hot-toast";
import { useEffect } from "react";

interface GetCommonQueryParams {
  queryKey: string | string[];
  link: string;
  isEnabled: boolean;
  onSuccess?: () => void;
  meta: any;
}

export const useGetQuery = <T>({
  queryKey,
  link,
  isEnabled,
  onSuccess = () => {},
  meta,
}: GetCommonQueryParams) => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    queryKey,
    async () => {
      const request = runBigTask(
        `${process.env.REACT_APP_API_URL}${link}`,
        localStorage.getItem("token") as string
      );
      toast.promise(
        request,
        {
          loading: "Loading...",
          success: `${meta} successful`,
          error: err => {
            if (err) {
              if (err.description) {
                return err.description;
              } else {
                return err.message;
              }
            } else {
              return "Something went wrong";
            }
          },
        },
        {
          style: {
            fontFamily: "Poppins, sans-serif",
            fontSize: "14px",
          },
        }
      );
      const res = await request;
      return res as T;
    },
    {
      onSuccess,
      enabled: isEnabled,
      refetchOnWindowFocus: false,
    }
  );

  return {
    data,
    refetch,
    isLoading: isLoading || isFetching,
  };
};
