import React, { useState, useRef } from "react";
import { FiUpload } from "react-icons/fi";
import { Portal } from "../../../Components/Portal";
import { useGetQuery } from "../../../Hooks/networkCalls/useGetQuery";
import { useUploadQuery } from "../../../Hooks/networkCalls/useUploadQuery";
import MenuOption from "../MenuOption";
import DataImporter from "./DataImporter";

function convertToCSV(objArray: any) {
  var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  var str = "";
  Object.keys(objArray[0]).forEach((key, index) => {
    if (key !== "errors") {
      if (index === 0) {
        str += key;
      } else {
        str += "," + key;
      }
    }
  });
  str += "\r\n";
  for (var i = 0; i < array.length; i++) {
    var line = "";
    for (var index in array[i]) {
      if (index !== "errors") {
        if (line != "") line += ",";
        line += array[i][index];
      }
    }
    str += line + "\r\n";
  }

  return str;
}

interface CsvImportProps {
  bulkUpdateConfigRoute: string;
  bulkUpdateApiRoute: string;
  bulkUpdateHttpMethod: string;
  tableToken: string;
}

const CsvImport: React.FC<CsvImportProps> = ({
  bulkUpdateApiRoute,
  bulkUpdateConfigRoute,
  bulkUpdateHttpMethod,
  tableToken,
}) => {
  const inputRef = useRef<any>();
  const [csv, setCsv] = useState<any>();
  const [showModal, setShowModal] = useState(false);

  const {
    data: bulkUpdateConfigData,
    refetch: getBulkUpdateConfig,
    // isLoading: bulkUpateConfigLoading,
  } = useGetQuery({
    queryKey: ["bulkUpdateConfig", tableToken],
    link: bulkUpdateConfigRoute,
    isEnabled: false,
    meta: "Bulk Update Config",
  });

  const {
    // data: fileUploadData,
    mutateAsync: fileUploadMutateAsync,
    // isLoading: fileUploadLoading,
  } = useUploadQuery({
    link: bulkUpdateApiRoute,
    httpMethod: bulkUpdateHttpMethod,
    meta: "File Upload",
    onSuccess: () => {
      setShowModal(false);
    },
  });

  const getConfig = () => {
    getBulkUpdateConfig();
    inputRef.current.click();
    setShowModal(true);
  };

  const submitData = (csvData: any) => {
    const csvString = convertToCSV(csvData);
    fileUploadMutateAsync({
      fileData: csvString,
      fileName: csv.name,
    });
  };

  return (
    <>
      <MenuOption icon={<FiUpload />} label={"Import"} onClick={getConfig} />
      <input
        style={{ display: "none" }}
        accept="text/csv"
        ref={inputRef}
        type="file"
        onChange={(e: any) => setCsv(e.target.files[0])}
      />
      {bulkUpdateConfigData && csv && showModal && (
        <Portal>
          <div className="fixed top-0 left-0 w-3/4 h-screen bg-white z-40 shadow-md">
            <DataImporter
              columns={bulkUpdateConfigData}
              csv={csv}
              submitData={submitData}
            />
          </div>
        </Portal>
      )}
      {showModal && (
        <div
          onClick={() => setShowModal(false)}
          className="w-screen h-screen fixed top-0 left-0 bg-white blur-2xl opacity-50 z-30"></div>
      )}
    </>
  );
};

export default CsvImport;
