import React from "react";

interface Icon {
  component: React.ReactNode;
  position: "left" | "right";
}

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  /** Selects a variant. */
  variant: "primary" | "secondary" | "outlined" | "text";

  /** Button Size */
  size?: "sm" | "md" | "lg" | "xs";

  /** Adds an icon to the button. */
  icon?: Icon;

  /** Is disabled. */
  disabled?: boolean;

  onClick: () => void;

  /** Button Text */
  fullWidth?: boolean;

  type?: "submit" | "reset" | "button";
}

/** A simple button.*/
export const Button: React.FC<ButtonProps> = ({
  children,
  variant = "outlined",
  size,
  icon,
  onClick = () => {},
  fullWidth = false,
  type = "button",
  ...props
}) => {
  let variantClass;
  if (variant === "outlined") {
    variantClass =
      "border border-indigo-700 hover:bg-indigo-600 text-indigo-700 hover:text-white";
  } else if (variant === "text") {
    variantClass = "text-indigo-700 hover:text-indigo-600";
  } else if (variant === "secondary") {
    variantClass =
      "text-white bg-gradient-to-br from-indigo-600 to-pink-500 hover:bg-gradient-to-bl";
  } else {
    variantClass = "text-white bg-indigo-700 hover:bg-indigo-600";
  }

  let sizeClass;
  if (size === "sm") {
    sizeClass = "py-2 px-3";
  } else if (size === "xs") {
    sizeClass = "py-1 px-2";
  } else if (size === "lg") {
    sizeClass = "py-3 px-5 text-xl";
  } else {
    sizeClass = "py-2.5 px-5";
  }

  return (
    <button
      type={type}
      onClick={onClick}
      className={`${
        fullWidth ? "w-full" : "w-auto"
      } font-sans flex items-center rounded-md ${sizeClass} ${variantClass} transition ease-in-out duration-200 focus:ring-1 focus:ring-black`}>
      {icon && (
        <span className={`${children ? "mr-2" : ""}`}>{icon.component}</span>
      )}
      {children}
    </button>
  );
};
