import React, { useState, useRef } from "react";
import { usePopper } from "react-popper";
import { Portal } from "../Portal";

interface DropdownProps {
  children: React.ReactNode;
  referenceEle: React.ReactNode;
  show: boolean;
  setShow: any;
  autoWidth?: boolean;
  onClose?: () => void;
  fullWidth?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  referenceEle,
  children,
  show,
  setShow,
  autoWidth,
  fullWidth,
  onClose = () => {},
}) => {
  const containerRef = useRef<any>();

  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 5],
        },
      },
    ],
  });

  return (
    <div className={fullWidth ? "w-full h-full" : ""} ref={containerRef}>
      <div
        ref={setReferenceElement}
        onClick={() => setShow((p: boolean) => !p)}
        className="w-full h-full">
        {referenceEle}
      </div>
      {show && (
        <Portal>
          <div
            ref={setPopperElement}
            style={{
              ...styles.popper,
              zIndex: 999,
              width: autoWidth ? "auto" : containerRef?.current?.offsetWidth,
              minWidth: 200,
            }}
            className="bg-white shadow-xl py-1 border ring-1 ring-indigo-500 relative rounded-md"
            {...attributes.popper}>
            {children}
          </div>
        </Portal>
      )}
    </div>
  );
};

export default Dropdown;
