import React, { useMemo } from "react";
import { DataCellProps } from ".";

const Status: React.FC<DataCellProps> = ({ value, statusColor }) => {
  const statusObject = useMemo(
    () => statusColor.find((s: any) => s.originalText === value),
    [value, statusColor]
  );
  return (
    <div
      style={{
        backgroundColor: statusObject?.backgroundColor,
        color: statusObject?.textColor,
      }}
      className="w-full h-full flex items-center justify-center rounded">
      {value}
    </div>
  );
};

export default Status;
