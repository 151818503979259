import { useMutation } from "react-query";
import { ApiError } from "./ApiError";
import { toast } from "react-hot-toast";

interface UploadQueryParams {
  link: string;
  httpMethod: string;
  onSuccess: (data: any) => void;
  meta: any;
  authType?: "jwt" | "basic";
}

export const useUploadQuery = ({
  link,
  httpMethod,
  onSuccess = () => {},
  meta,
  authType = "jwt",
}: UploadQueryParams) => {
  let authToken: string;
  if (authType === "jwt") {
    authToken = `Bearer ${localStorage.getItem("token")}`;
  } else {
    authToken = `Basic NC1VKGVMeTNhR1MsI200ZEskNjh1aDZnS3p2dWdURyM6cClIPU1iQHldbWgpcmc4Z2RBey0ufVU0eGE2dkFlMnk=`;
  }

  const uploadData = async (submitObj: any) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}${link}`, {
        method: httpMethod,
        body: JSON.stringify(submitObj),
        headers: {
          authorization: authToken,
        },
      });
      const parsedRes = await res.json();
      if (res.status >= 300 && !res.ok) {
        throw new ApiError(
          parsedRes.httpResonseCode,
          parsedRes.debugDescription,
          parsedRes.description,
          parsedRes
        );
      } else {
        return parsedRes;
      }
    } catch (err) {
      throw err;
    }
  };

  const { mutateAsync, data, isLoading } = useMutation(
    async (submitObj: any) => {
      const promise = uploadData(submitObj);
      toast.promise(
        promise,
        {
          loading: "Loading...",
          success: () => "",
          error: err => {
            if (err) {
              if (err.description) {
                return err.description;
              } else {
                return err;
              }
            } else {
              return "Something went wrong";
            }
          },
        },
        {
          style: {
            fontFamily: "Poppins, sans-serif",
            fontSize: "14px",
          },
        }
      );
      const response = await promise;
      return response;
    },
    {
      onSuccess: onSuccess,
      meta,
    }
  );

  return {
    data,
    mutateAsync,
    isLoading: isLoading,
  };
};
