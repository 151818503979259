import { FormHelperText } from "@mui/material";
import { useField } from "formik";
import { FC } from "react";
import { FiCheckCircle, FiCircle } from "react-icons/fi";
import { CustomSelect } from "../../../CustomSelect";
import { compareSortingParameters } from "../../shared";

interface FilterOption {
  name: string;
  value: string;
}

interface Props {
  label: string;
  name: string;
  options: FilterOption[];
  multiple?: boolean;
}

export const Select: FC<Props> = ({ name, label, options, multiple }) => {
  const [field, meta, helpers] = useField({ name });
  const { touched, error } = meta;

  const getValue = () => {
    if (field.value) return field.value;
    if (multiple) return [];
    return "";
  };

  return (
    <>
      <CustomSelect
        value={getValue()}
        onChange={(value: FilterOption[] | FilterOption | null) =>
          helpers.setValue(value)
        }
        label={label}
        name={name}
        options={options}
        multiple={multiple}
        error={touched.valueOf() ? error : ""}
      />
    </>
  );
};
