import {
  Autocomplete,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  Checkbox,
  Chip,
  FormHelperText,
  ListItem,
  TextField,
} from "@mui/material";
import { useField } from "formik";
import { FC } from "react";
import { FiCheckCircle, FiCircle } from "react-icons/fi";
import { compareSortingParameters } from "../Form/shared";

interface FilterOption {
  name: string;
  value: string;
}

interface Props {
  label: string;
  name: string;
  value: FilterOption | FilterOption[];
  onChange: (data: FilterOption | FilterOption[] | null) => void;
  options: FilterOption[];
  multiple?: boolean;
  error?: string;
}

export const CustomSelect: FC<Props> = ({
  name,
  label,
  options,
  multiple,
  value,
  onChange,
  error,
}) => {
  return (
    <>
      <Autocomplete
        sx={{ width: "100%" }}
        key={name}
        size="small"
        multiple={multiple}
        disableCloseOnSelect={multiple}
        defaultChecked
        value={value}
        options={options.sort(compareSortingParameters)}
        getOptionLabel={(option: FilterOption) =>
          option.name ? option.name : ""
        }
        onChange={(_, value: FilterOption[] | FilterOption | null) =>
          onChange(value)
        }
        renderTags={(value, getTagProps: AutocompleteRenderGetTagProps) => (
          <AutoCompleteTags value={value} getTagProps={getTagProps} />
        )}
        renderOption={(props, option, { selected }) => (
          <AutoCompleteOptions
            key={props.id}
            props={props}
            option={option}
            selected={selected}
          />
        )}
        renderInput={params => (
          <AutoCompleteInput params={params} label={label} />
        )}
      />
      <FormHelperText error={Boolean(error)}>{error && error}</FormHelperText>
    </>
  );
};
const AutoCompleteTags: FC<{
  value: FilterOption[];
  getTagProps: AutocompleteRenderGetTagProps;
}> = ({ value, getTagProps }) => {
  return (
    <>
      {value.map((item, index: number) => (
        <Chip
          variant="outlined"
          label={item.name}
          size="small"
          {...getTagProps({ index })}
        />
      ))}
    </>
  );
};

const AutoCompleteOptions: FC<{
  props: any;
  option: FilterOption;
  selected: boolean;
}> = ({ props, option, selected }) => {
  return (
    <ListItem
      {...props}
      disableGutters
      disablePadding
      style={{ fontSize: "14px" }}>
      <Checkbox
        icon={<FiCircle style={{ fontSize: "18px" }} />}
        checkedIcon={<FiCheckCircle style={{ fontSize: "18px" }} />}
        checked={selected}
      />
      {option.name}
    </ListItem>
  );
};

const AutoCompleteInput: FC<{
  label: string;
  params: AutocompleteRenderInputParams;
}> = ({ label, params }) => {
  return (
    <TextField
      {...params}
      variant="outlined"
      label={label}
      placeholder={label}
    />
  );
};
