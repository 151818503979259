import { useEffect, useState, useMemo } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Services } from "./types/components";
import { Toaster } from "react-hot-toast";

import SignIn from "./Pages/SignIn";
import Pages from "./Pages";
import "./App.css";
import { useGetQuery } from "./Hooks/networkCalls/useGetQuery";
import CustomCursor from "./Components/CustomCursor";
import NewDropdown from "./Components/Dropdown/index2";
import BrandImageUpload from "./Pages/BrandImageUpload";

const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  const { data: loadedMenu, refetch } = useGetQuery<Services>({
    queryKey: "navigation",
    link: "/v6/admin/staff/services",
    meta: "Navigation Fetched Successfully",
    isEnabled: false,
    onSuccess: () => {},
  });

  useEffect(() => {
    if (isLoggedIn) {
      refetch();
    }
  }, [isLoggedIn, refetch]);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    document.body.classList.add("blue");
  }, []);

  return (
    <BrowserRouter>
      <div className="w-screen h-screen overflow-y-scroll">
        <Switch>
          <Route path="/signin">
            <SignIn setIsLoggedIn={setIsLoggedIn} />
          </Route>
          <Route path="/warehouse-image-upload">
            <BrandImageUpload />
          </Route>
          {loadedMenu && (
            <Route path="/">
              <Pages menus={loadedMenu} />
            </Route>
          )}
        </Switch>
        <CustomCursor />
        <Toaster />
        <NewDropdown />
      </div>
    </BrowserRouter>
  );
};

export default App;
