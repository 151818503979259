export class ApiError {
  code: number;
  title: string;
  description: string;
  error: any;

  constructor(code: number, title: string, description: string, error: any) {
    this.code = code;
    this.title = title;
    this.description = description;
    this.error = error;
  }
}
