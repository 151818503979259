import React, { useState } from "react";
import { FiPlusCircle, FiX } from "react-icons/fi";
import { useGetQuery } from "../../../Hooks/networkCalls/useGetQuery";
import MenuOption from "../MenuOption";
import { Form } from "../../../Components/Form";
import { useUploadQuery } from "../../../Hooks/networkCalls/useUploadQuery";
import { useQueryClient } from "react-query";
import {
  Box,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import { uploadFile } from "../../../Utils";

interface CreateProductProps {
  createConfigRoute: string;
  createApiRoute: string;
  createHttpMethod: string;
  tableToken: string;
  resetData: () => void;
}
const CreateProduct: React.FC<CreateProductProps> = ({
  createConfigRoute,
  tableToken,
  createApiRoute,
  createHttpMethod,
  resetData,
}) => {
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);
  const {
    data: createFormData,
    refetch: getCreateConfig,
    // isLoading: createConfigLoading,
  } = useGetQuery<any>({
    queryKey: ["createConfigRoute", tableToken],
    link: createConfigRoute,
    isEnabled: false,
    onSuccess: () => setShowModal(p => !p),
    meta: "Create Config",
  });

  const { mutateAsync: createApiMutateAsync } = useUploadQuery({
    link: createApiRoute,
    httpMethod: createHttpMethod,
    onSuccess: () => {
      resetData();
      queryClient.invalidateQueries(tableToken);
      setShowModal(false);
    },
    meta: "Create item",
  });

  const submitHandler = async (values: any) => {
    const promise: Promise<{ key: string | number | symbol; value: string }>[] =
      [];
    (Object.keys(values) as Array<keyof typeof values>).forEach(key => {
      if (values[key] instanceof File) {
        promise.push(uploadFile(values[key], key));
      } else if (values[key] instanceof Array) {
        values[key] = values[key].map((v: any) => v.value.trim()).join(",");
      } else if (typeof values[key] === "object") {
        values[key] = values[key].value;
      }
    });
    const data = await Promise.all(promise);
    data.forEach(d => {
      if (values[d.key] && values[d.key] instanceof File) {
        values[d.key] = d.value;
      }
    });
    values.token = createFormData.token;
    createApiMutateAsync(values).then(res => {
      setShowModal(false);
    });
  };

  return (
    <>
      <MenuOption
        icon={<FiPlusCircle />}
        label={"Create"}
        onClick={getCreateConfig}
      />
      {showModal && createFormData && (
        <div>
          <Dialog
            open={showModal}
            onClose={() => setShowModal(false)}
            fullScreen={true}>
            <Form data={createFormData} submitHandler={submitHandler}>
              <CardHeader
                title={<Form.Tabs />}
                action={
                  <IconButton
                    onClick={() => setShowModal(false)}
                    sx={{ mt: 1 }}>
                    <FiX />
                  </IconButton>
                }
              />
              <DialogContent>
                <Box
                  sx={{ pt: 1 }}
                  display="grid"
                  gridTemplateColumns="1fr 1fr"
                  gap={2}
                  alignItems="flex-end">
                  <Form.FormContent />
                </Box>
              </DialogContent>
              <DialogActions>
                <Form.ActionButtons />
              </DialogActions>
            </Form>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default CreateProduct;
