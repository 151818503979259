import React, { useState, useCallback, useRef } from "react";
import { FiMaximize2 } from "react-icons/fi";
import Dropdown from "../../../Components/Dropdown";
import MenuOption from "../MenuOption";
import debounce from "lodash.debounce";
import { useClickOutside } from "../../../Hooks/utils/useClickOutside";

interface RowResizeProps {
  setRowSize: (size: number) => void;
}

const RowResize: React.FC<RowResizeProps> = ({ setRowSize }) => {
  const [show, setShow] = useState(false);
  const containerRef = useRef<any>(null);

  const setSize = (e: any) => {
    setRowSize(e.target.value);
  };

  const toggleShow = useCallback(() => {
    setShow(!show);
  }, [show]);
  useClickOutside(containerRef, toggleShow);

  const debounceHandler = useCallback(debounce(setSize, 200), []);

  return (
    <Dropdown
      referenceEle={
        <MenuOption
          icon={<FiMaximize2 />}
          label={"Resize"}
          onClick={() => setShow(p => !p)}
        />
      }
      show={show}
      setShow={() => {}}>
      <div
        ref={containerRef}
        className="p-2 flex flex-col space-y-2 items-start">
        <label className="font-sans text-sm">Resize Row</label>
        <input
          type="range"
          className="w-full"
          min={40}
          max={1000}
          onChange={debounceHandler}
        />
      </div>
    </Dropdown>
  );
};

export default RowResize;
