import {
  Autocomplete,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  Checkbox,
  Chip,
  FormHelperText,
  ListItem,
  TextField,
} from "@mui/material";
import { useField } from "formik";
import { FC } from "react";
import { FiCheckCircle, FiCircle } from "react-icons/fi";
import { compareSortingParameters } from "../../shared";

interface FilterOption {
  name: string;
  value: string;
}

interface Props {
  label: string;
  name: string;
  options: FilterOption[];
  multiple?: boolean;
}

export const Select: FC<Props> = ({ name, label, options }) => {
  const [field, meta, helpers] = useField({ name });
  const { touched, error } = meta;
  return (
    <>
      <Autocomplete
        key={name}
        size="small"
        multiple
        id="tags-standard"
        disableCloseOnSelect
        defaultChecked
        value={field.value ? field.value : []}
        options={options.sort(compareSortingParameters)}
        getOptionLabel={(option: FilterOption) => option.name}
        onChange={(_, value: FilterOption[]) => helpers.setValue(value)}
        renderTags={(value, getTagProps: AutocompleteRenderGetTagProps) => (
          <AutoCompleteTags value={value} getTagProps={getTagProps} />
        )}
        renderOption={(props, option, { selected }) => (
          <AutoCompleteOptions
            key={props.id}
            props={props}
            option={option}
            selected={selected}
          />
        )}
        renderInput={params => (
          <AutoCompleteInput params={params} label={label} />
        )}
      />
      <FormHelperText error={touched.valueOf() && Boolean(error)}>
        {touched.valueOf() && error}
      </FormHelperText>
    </>
  );
};
const AutoCompleteTags: FC<{
  value: FilterOption[];
  getTagProps: AutocompleteRenderGetTagProps;
}> = ({ value, getTagProps }) => {
  return (
    <>
      {value.map((item, index: number) => (
        <Chip
          variant="outlined"
          label={item.name}
          size="small"
          {...getTagProps({ index })}
        />
      ))}
    </>
  );
};

const AutoCompleteOptions: FC<{
  props: any;
  option: FilterOption;
  selected: boolean;
}> = ({ props, option, selected }) => {
  return (
    <ListItem
      {...props}
      disableGutters
      disablePadding
      style={{ fontSize: "14px" }}>
      <Checkbox
        icon={<FiCircle style={{ fontSize: "18px" }} />}
        checkedIcon={<FiCheckCircle style={{ fontSize: "18px" }} />}
        checked={selected}
      />
      {option.name}
    </ListItem>
  );
};

const AutoCompleteInput: FC<{
  label: string;
  params: AutocompleteRenderInputParams;
}> = ({ label, params }) => {
  return (
    <TextField
      {...params}
      variant="outlined"
      label={label}
      placeholder={label}
    />
  );
};
