import React, { useState, useRef, useEffect } from "react";
import { SetStateAction } from "react";
import { Dispatch } from "react";
import { usePopper } from "react-popper";
import { Portal } from "../Portal";

const referanceListeners: Dispatch<SetStateAction<HTMLDivElement | null>>[] =
  [];
const showListener: Dispatch<SetStateAction<boolean>>[] = [];
const contentListener: React.Dispatch<React.SetStateAction<null>>[] = [];

export const openDropdown = (
  referanceEle: HTMLDivElement | null,
  content: () => React.ReactNode
) => {
  referanceListeners.forEach((listener: any) => {
    listener(referanceEle);
  });
  showListener.forEach((listener: any) => {
    listener(true);
  });
  contentListener.forEach((listener: any) => {
    listener(content());
  });
};

export const closeDropdown = () => {
  referanceListeners.forEach((listener: any) => {
    listener(null);
  });
  showListener.forEach((listener: any) => {
    listener(false);
  });
  contentListener.forEach((listener: any) => {
    listener(null);
  });
};

const NewDropdown: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [content, setContent] = useState(null);
  const [show, setShow] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 5],
        },
      },
    ],
  });

  useEffect(() => {
    referanceListeners.push(setReferenceElement);
    return () => {
      referanceListeners.splice(
        referanceListeners.indexOf(setReferenceElement),
        1
      );
    };
  }, [referenceElement]);
  useEffect(() => {
    showListener.push(setShow);
    return () => {
      showListener.splice(showListener.indexOf(setShow), 1);
    };
  }, [show]);
  useEffect(() => {
    contentListener.push(setContent);
    return () => {
      contentListener.splice(contentListener.indexOf(setContent), 1);
    };
  }, [content]);

  return (
    <div ref={containerRef}>
      {show && (
        <Portal>
          <div
            ref={setPopperElement}
            style={{
              ...styles.popper,
              zIndex: 999,
              width: referenceElement?.offsetWidth,
              minWidth: 200,
            }}
            className="bg-white shadow-xl py-1 border ring-1 ring-indigo-500 relative rounded-md"
            {...attributes.popper}>
            {content}
          </div>
        </Portal>
      )}
    </div>
  );
};

export default NewDropdown;
