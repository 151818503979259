import { useRef } from "react";

export const withImage = (Component: any) => {
  return (props: any) => {
    const { setValue } = props;
    const fileInputRef = useRef(null);
    const deleteImage = () => {
      setValue(undefined);
    };
    return (
      <>
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: "none" }}
          onChange={(e: any) => {
            setValue(e.target.files[0]);
          }}
        />
        <Component
          fileInputRef={fileInputRef}
          {...props}
          deleteImage={deleteImage}
        />
      </>
    );
  };
};
