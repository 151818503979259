import { Button } from "../../../Components/Button";

interface MenuOptionInterface {
  icon: React.ReactNode;
  label: string;
  type?: "primary" | "secondary" | "outlined" | "text";
  onClick: () => void;
}

const MenuOption: React.FC<MenuOptionInterface> = ({
  icon,
  label,
  type = "text",
  onClick,
}) => {
  return (
    <div className="shadow-md rounded p-0.5">
      <Button
        variant={type}
        size="xs"
        icon={{
          component: icon,
          position: "left",
        }}
        onClick={onClick}>
        <span className="text-gray-700 hover:text-indigo-700">{label}</span>
      </Button>
    </div>
  );
};

export default MenuOption;
